<template lang="pug">
  include ./../../../mixins
  +b.A.submenu-nav-link(
    href='#'
    @click='openModal()'
  )
    +e.I.icon.icon-exit
    +e.span.title {{ _("Вийти") }}
</template>

<script>
import LogoutModal from '@components/Modals/LogoutModal'

export default {
  data() {
    return {}
  },
  methods: {
    openModal() {
      this.$modal.show(
        LogoutModal,
        {},
        {
          height: 'auto',
          width: 500,
          adaptive: true,
          scrollable: false,
          transition: 'false',
        }
      )
    },
  },
}
</script>
