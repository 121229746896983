import {
// path
} from '@/consts'

export function createRouter({ Router }) {
  const router = new Router({
    mode: 'history',
    base: '/',
    routes: [],
  })

  return router
}
