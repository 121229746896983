import { createStore as createSiteStore } from './site/store'

export function createStore(parameters) {
  return {
    modules: {
      site: createSiteStore(parameters),
    },
  }
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }))
}
