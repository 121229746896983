<template lang="pug">
  include ./../../../mixins
  +b.BUTTON.btn-base--blue.--txt-center(
    v-if='btnTitle'
    @click='setCourseStatus()'
  ) {{ btnTitle }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import { getCommonParsedErrors } from '@mixins/SendMixin'
import {
  startCourseResource,
  finishCourseResource,
} from '@services/courses.service'

export default {
  props: {
    pk: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      btnTitle: '',
    }
  },
  mounted() {
    if ('wait' === this.status) {
      this.btnTitle = this._('Розпочати')
    } else if ('progress' === this.status) {
      this.btnTitle = this._('Завершити')
    }
  },
  methods: {
    async setCourseStatus() {
      if ('wait' === this.status) {
        startCourseResource.execute(
          { pk: this.pk },
          { status: 'progress' }
        ).then(() => {
          // console.log(data)
          window.location.reload()
        }).catch(async error => {
          const parsed = await getCommonParsedErrors(error)

          if (parsed && parsed.status) {
            const [firstItem] = parsed.status
            const { message } = firstItem

            this.$modal.show(
              InfoModal,
              {
                title: this._('Увага'),
                information: message,
              },
              {
                height: 'auto',
                width: 360,
                adaptive: true,
                scrollable: false,
                transition: 'false',
              },
              {
                'before-close': () => {
                  window.location.reload()
                },
              }
            )
          }
        })
      } else if ('progress' === this.status) {
        finishCourseResource.execute(
          { pk: this.pk },
          { status: 'finished' }
        ).then(() => {
          // console.log(data)
          window.location.reload()
        }).catch(async error => {
          const parsed = await getCommonParsedErrors(error)

          if (parsed && parsed.status) {
            const [firstItem] = parsed.status
            const { message } = firstItem

            this.$modal.show(
              InfoModal,
              {
                title: this._('Увага'),
                information: message,
              },
              {
                height: 'auto',
                width: 360,
                adaptive: true,
                scrollable: false,
                transition: 'false',
              },
              {
                'before-close': () => {
                  window.location.reload()
                },
              }
            )
          }
        })
      }
    },
  },
}
</script>
