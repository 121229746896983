/* eslint-disable */

import $ from 'jquery'
window.$ =  $
// import debounce from 'lodash.debounce'

// const { language } = window

// export function checkLogo() {
//   const location = ['/', `/${language}/`, '/index.html']
//   const onesec = 1
//   location.forEach(el => {
//     const logo = Array.from(document.getElementsByClassName('logo-wrapper'))
//     if (el == window.location.pathname) {
//       setTimeout(() => {
//         logo.forEach(element => {
//           element.style.pointerEvents = 'none'
//         })
//       }, onesec)
//       return
//     }
//     logo.forEach(element => {
//       element.style.pointerEvents = 'all'
//     })
//   })
// }

// const PERFECT_SCREEN = 1920 
// const PERFECT_SCREEN_HEIGHT = 970
// let initialWidth

// export const sizer = function(to) {
//   initialWidth = window.innerWidth
//   if (initialWidth > 1900) return
//   const stop = document.getElementById('stopsizer')
//   if (stop) return

//   const zoom = initialWidth / PERFECT_SCREEN
//   document.body.style.zoom = zoom
//   $('body').attr({'data-zoom': zoom})

//   const els = Array.from(document.getElementsByClassName('custom-height'))
//   const main = Array.from(document.getElementsByClassName('main-page'))
//   const [header] = Array.from(document.getElementsByClassName('header-sec'))
//   els.forEach(el => el.style.height = `${PERFECT_SCREEN_HEIGHT}px`)
//   main.forEach(el => el.style.height = `${PERFECT_SCREEN_HEIGHT - header.offsetHeight}px`)
//   if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
//     $.cssNumber.zoom = true;

//     $.cssHooks.zoom = {
//       get: function(elem, computed, extra) {
//         var value = $(elem).data('zoom');
//         return value != null ? value : 1;
//       },
//       set: function(elem, value) {
//         var $elem = $(elem);
//         var size = { // without margin
//           width: $elem.outerWidth(),
//           height: $elem.outerWidth()
//         };
//         $elem.data('zoom', value);
//         if (value != 1) {
//           const vertical = (size.width * value - size.width) / 2
//           const horizontal = (PERFECT_SCREEN_HEIGHT * value - PERFECT_SCREEN_HEIGHT) / 2

//           $elem.css({
//             transform: 'scale(' + value + ')',
//             marginLeft: vertical,
//             marginRight: vertical,
//             marginTop: horizontal,
//             marginBottom: horizontal,
//           });
//           $elem.attr({
//             'data-zoom': zoom,
//           });
//         } else {
//           $elem.css({
//             transform: null,
//             margin: null
//           });
//           $elem.attr({
//             'data-zoom': zoom,
//           });
//         }
//       }
//     };
//     $('body').attr({'data-zoom': zoom})
//     $('body').css('zoom', zoom)
//   }
  
// }

// sizer()
// const debouncedResizer = debounce(e => {
//   if (initialWidth !== e.target.innerWidth) {
//     sizer()
//   }
// }, 200)
// window.addEventListener('resize', debouncedResizer, true);

export function goToTop() {
  let xl = 1200
  if (window.innerWidth > xl) {
    let btnScroll = document.getElementById('go-to-top')
    let offset = 200
    if (!btnScroll) return
    if (document.body.scrollTop > offset || 
      document.documentElement.scrollTop > offset) {
      btnScroll.style.display = "inline-flex"
    } else {
      btnScroll.style.display = "none"
    }
  }
}

export function scrolledHeader() {
  const { innerWidth } = window
  const xl = 1200

  if (xl >= innerWidth) {
    const header = document.querySelector('.js-header')
    const start = 0
    function handleScroll() {
      if (window.pageYOffset > start) {
        header.classList.add('is-scrolled')
      } else {
        header.classList.remove('is-scrolled')
      }
    }
    document.addEventListener('scroll', handleScroll)
  }
}
