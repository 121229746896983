import {
  createResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const START_TEST_LINK = prefixAPI('/start/', 'tests')
const QUESTIONS_OF_TEST_LINK = prefixAPI('/questions/{?pk}', 'tests')
const TEST_OF_USER_LINK = prefixAPI('/test_of_user/{?pk}', 'tests')
const FINISH_TEST_LINK = prefixAPI('/finish/', 'tests')
const REQUEST_TO_RESTART_TEST = prefixAPI('/request-to-restart-test/', 'tests')

export const startTestResource = createResource(
  START_TEST_LINK, postResource
)
export const questionsOfTestResource = createResource(
  QUESTIONS_OF_TEST_LINK
)
export const testOfUserResource = createResource(
  TEST_OF_USER_LINK
)
export const finishTestResource = createResource(
  FINISH_TEST_LINK, postResource
)
export const requestToRestartTestResource = createResource(
  REQUEST_TO_RESTART_TEST, postResource
)
