<template lang="pug">
  include ./../../../mixins
  +b.change-control-wrapper
    +b.cabinet-avatar-wrapper
      +e.img-wrapper
        +e.IMG.image(
          :src='formData.avatar'
        )
        +e.btn.btn-avatar(@click="$refs.uploadAvatar.click()")
          +b.I.icon.icon-pencil
      input(
        ref='uploadAvatar'
        class='upload-avatar'
        type="file"
        accept="image/*"
        @change="onChangeAvatar"
      )
    +b.server-error(v-if="serverError") {{ serverError }}
</template>

<script>
import {
  userSetAvatarResource,
  userDetailInfoResource,
} from '@services/users.service'

export default {
  data() {
    return {
      formData: {
        avatar: '',
      },
      serverError: '',
      isLoading: false,
      user: {},
    }
  },
  async mounted() {
    await this.getUserInfo()
  },
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async getUserInfo() {
      await userDetailInfoResource.execute(
        {}
      ).then(({ data }) => {
        const { item } = data
        this.formData.avatar = item.avatar
      })
    },
    onChangeAvatar(e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      const fileName = file.name
      const promise = this.getBase64Image(url)
      this.isLoading = true
      promise.then(
        dataURL => userSetAvatarResource.execute(
          { pk: this.userId },
          { avatar: `${fileName};${dataURL}` }
        )
          .then(({ data }) => {
            const { item } = data
            this.formData.avatar = item.avatarUrl
          })
      );
      this.isLoading = false
    },
    getBase64Image(url) {
      const promise = new Promise(resolve => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const dataURL = canvas.toDataURL('image/png')
          resolve(dataURL)
        };
        img.src = url
      })
      return promise
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
