<script>
export default {
  props: {
    text: {
      type: String,
    },
  },

  methods: {
    doCopy() {
      this.$copyText(this.text).then(() => {
        this.$notify({ title: this._('Скопійовано'), type: 'success' })
      }, () => {
        this.$notify({ title: this._('Не вдалося скопіювати'), type: 'error' })
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      doCopy: this.doCopy,
    })
  },
}
</script>
