<template lang="pug">
  include /mixins
  +b.popup-wrapper--forms
    +b.i-preloader.--absolute(v-if="isLoading")
      +e.item
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title--forms {{ _('Підтвердити зміну адреси ел. пошти') }}
    +b.form-top-info-description--forms
      +b.SPAN.form-title {{ _("На вашу ел. пошту") }}
      +e.SPAN.email.form-title &nbsp{{ email }}&nbsp
      +b.SPAN.form-title {{ _("було відправлено листа з кодом підтвердження") }}
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element"
      @submit.prevent='prepareData'
      v-slot="{ valid, errors: globalErrors }"
    )
      +b.server-error(v-if="serverError") {{ serverError }}
      +b.form-inputs-wrap
        +b.form-inputs
          +b.form-inp-group
            +b.form-inp--forms
              +b.input_label {{ _("6-ти значний код**") }}
              +b.I.icon.icon-password-secret.input_icon(
                @click="switchVisibility"
                v-if="typeOfPassword === 'password'"
              )
              +b.I.icon.icon-password-public.input_icon(
                @click="switchVisibility"
                v-else
              )
              input-group(
                rules='required|min:6',
                :type='typeOfPassword',
                id="confirmCode",
                name="confirmCode",
                placeholder="•••••••••••••••",
                v-model="formData['confirmCode']",
                vid="confirmCode",
                :class="{'is-error': showInputError(globalErrors, 'confirmCode')}",
              ).input_style
                template(v-slot:error="{errors}")
                  transition(name="fade")
                    span.help-type-error(v-if='errors.length') {{ errors[0] }}
      +b.BUTTON.btn-base--blue.--txt-center(
        type="submit",
        @click.prevent='prepareData'
      ) {{ _('Підтвердити зміну пошту') }}
</template>

<script>
import {
  confirmChangeEmailRequestResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  props: {
    promise: {},
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        confirmCode: '',
      },
      serverError: '',
      typeOfPassword: 'password',
      isLoading: false,
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        confirmCode: this.formData.confirmCode,
      })
    },
    send(sendData) {
      this.isLoading = true

      return confirmChangeEmailRequestResource.execute(
        {},
        sendData
      ).then(() => {
        this.$refs.validator.reset()
        this.promise(true)
        this.resetData()
        this.$emit('close')
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        confirmCode: '',
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        const [serverError] = parsed.nonFieldErrors
        this.serverError = serverError
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    switchVisibility() {
      this.typeOfPassword = 'password' === this.typeOfPassword ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
