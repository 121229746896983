<template lang="pug">
  include ./../../../mixins
  +b.form-content-wrapper
    +b.form-body--feedback
      +b.i-preloader.--absolute(v-if="isLoading")
        +e.item
      +b.form-top-info {{ _("Зв'язатися із нами") }}
      validation-observer(
        ref="validator"
        tag="form"
        class="form-element"
        @submit.prevent='prepareData'
        v-slot="{ valid, errors: globalErrors }"
      )
        +b.server-error(v-if="serverError") {{ serverError }}
        +b.form-inputs-wrap
          +b.form-inputs
            +b.form-inp-group
              +b.g-row--space_26
                +b.g-cell.g-cols--12-xs.--6-md.--12-lg.--6-xl.form-inp
                  +b.input_label {{ _("Ім'я*") }}
                  input-group(
                    rules='required',
                    type="text",
                    id="name",
                    name="name",
                    placeholder='Богдан',
                    v-model="formData['name']",
                    :class="{'is-error': showInputError(globalErrors, 'name')}",
                  ).input_style
                    template(v-slot:error="{errors}")
                      transition(name="fade")
                        span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--6-md.--12-lg.--6-xl.form-inp
                  +b.input_label {{ _("Електронна пошта*") }}
                  input-group(
                    rules='required|email'
                    type="email"
                    id="email"
                    name="email"
                    placeholder="mail@gmail.com"
                    v-model="formData['email']"
                    :class="{'is-error': showInputError(globalErrors, 'email')}"
                  ).input_style
                    template(v-slot:error="{errors}")
                      transition(name="fade")
                        span.help-type-error(v-if='errors.length') {{ errors[0] }}
              +b.form-inp.multiselect-wrapper
                +b.input_label {{ _("Тип звернення*") }}
                validation-provider(
                  v-slot="{ errors }"
                  name="typeOfRequest"
                  rules='required'
                )
                  multiselect.form-single-select(
                    v-model="formData.typeOfRequest"
                    label="title"
                    track-by="id"
                    open-direction="bottom"
                    :options="typesOfRequests"
                    :class="{'is-error': errors && errors.length}"
                    placeholder="Тип звернення"
                    :disabled="false"
                  )
                  +b.P.help-type-error(v-if="errors[0]") {{ errors[0] }}
              +b.form-inp
                +b.input_label {{ _("Запитання або коментар*") }}
                validation-provider(
                  id="comment"
                  name="comment"
                  rules="required",
                  v-slot="{ validate, errors }",
                  tag="div"
                  :class="{'is-error': showInputError(globalErrors, 'comment')}"
                ).input_style
                  +b.TEXTAREA(
                    ref='comment',
                    rows="8"
                    :disabled='false',
                    placeholder="",
                    v-model="formData.comment"
                  )
                  +b.P.help-type-error(v-if="errors[0]") {{ errors[0] }}
              +b.btn-wrapper
                +b.BUTTON.btn-base--blue(
                  type="submit",
                  @click.prevent='prepareData'
                ) {{ _("Надіслати") }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import {
  userDetailInfoResource,
} from '@services/users.service'
import {
  typesOfRequestsResource,
  feedbackCreateResource,
} from '@services/businessRequests.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  data() {
    return {
      formData: {
        email: '',
        name: '',
        typeOfRequest: '',
        comment: '',
      },
      serverError: '',
      isLoading: false,
      typesOfRequests: [],
    }
  },
  async mounted() {
    await this.getTypesOfRequests()
    await this.getUserInfo()
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        name: this.formData.name,
        email: this.formData.email,
        comment: this.formData.comment,
        typeOfRequest: this.formData.typeOfRequest.id,
      })
    },
    send(data) {
      this.isLoading = true

      return feedbackCreateResource.execute({}, data).then(() => {
        this.$refs.validator.reset()
        // якщо все добре, то відкриваємо попап з інфою
        const title = this._('Дякуємо!')
        const information = 'Ваше звернення прийнято'
        this.$modal.show(
          InfoModal,
          {
            title,
            information,
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          }
        )
        // Обнуляємо дані форми
        this.resetData()
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        email: '',
        name: '',
        typeOfRequest: '',
        comment: '',
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        const [serverError] = parsed.nonFieldErrors
        this.serverError = serverError
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    async getUserInfo() {
      await userDetailInfoResource.execute(
        {}
      ).then(({ data }) => {
        const { item } = data
        this.formData.email = item.email
        this.formData.name = item.firstName
      })
    },
    async getTypesOfRequests() {
      await typesOfRequestsResource.execute(
        {}
      ).then(({ data }) => {
        const { items } = data
        this.typesOfRequests = items
      })
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
