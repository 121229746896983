<template lang='pug'>
  include /mixins
  +b.i-filter
    +e.button(@click.prevent="toggleFilter")
      +e.SPAN.button-text {{ _("Розширений пошук") }}
      +e.button-icon.--size_md(v-if="isActive")
        +b.I.icon-bootsrap-x
      +e.button-icon(v-else)
        +b.I.icon-sliders
    +e.view(:class="{'is-active': isActive}")
      //- +b.blur(
      //-   @click.prevent="hideFilter"
      //-   :class="{'is-hidden': !isActive}"
      //- )
      +e.wrapper
        +e.main
          +b.i-preloader.--variant_1(v-if="loading")
            +e.item.--size_md
          +e.content
            +b.i-panel
              filter-ui(
                ref="filterUi"
                :disabled="loading"
                :value="prepared"
                :dynamic-filters="dynamicFilters"
                :pagination='pagination'
                :additional-resource="additionalResource"
                :label="label"
                :need-search="needSearch"
                :need-price="needPriceFilter"
                :search-filter="searchFilter"
                :price-filter="priceFilter"
                @input="handleInputFilter"
                @clear="handleInputFilter({}, 'clear')"
              )
        +e.footer
          +b.BUTTON.control-button(@click.prevent="hideFilter")
            +e.element
              +e.SPAN.text {{ _("Применить") }}
</template>

<script>
import isShallowEqual from '@wordpress/is-shallow-equal'
// import { toggleOverflow } from '@utils/toggleOverflow'
import FilterUi from './Ui'

export default {
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'pagination',
    'label',
    'additionalResource',
    'totalItems',
    'needSearch',
    'needPriceFilter',
    'priceFilterTitle',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
      isActive: false,
      searchFilter: {
        filterWidgetType: 'input',
        placeholder: this._('Назва оголошення або ID'),
        name: 'search',
      },
      priceFilter: {
        filterWidgetType: 'price',
        placeholder: this.priceFilterTitle,
        name: 'price',
        items: [
          [{ value: 0, title: '0' }, { value: 100, title: '100' }],
          [{ value: 101, title: '101' }, { value: 500, title: '500' }],
          [{ value: 501, title: '501' }, { value: 1000, title: '1000' }],
          [{ value: 1001, title: '1001' }, { value: 1500, title: '1500' }],
          [{ value: 1501, title: '1501' }, { value: 2000, title: '2000' }],
          [{ value: 2001, title: this._('2001 та вище') }, { value: null }],
        ],
        currencies: [
          {
            slug: 'sterling',
            label: '£',
          },
          {
            slug: 'dollar',
            label: '$',
          },
          {
            slug: 'euro',
            label: '€',
          },
        ],
      },
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }

        this.prepare(filters)
      },
    },
  },

  computed: {
    totalCount() {
      return Object.hasOwnProperty.call(this.pagination, 'total') ? this.pagination.total : this.value.total
    },

    dynamicFilters() {
      const staticFilters = this.prepareStaticAttributes(this.all)
      const dynamicFilters = this.all ? this.all.dynamicAttributes.map(el => el.props) : []

      return [...staticFilters, ...dynamicFilters]
    },
  },

  created() {
    this.receive({ category: this.label })
  },

  methods: {
    prepareStaticAttributes(all) {
      if (all && all.attributes) {
        let attrs = all.attributes

        if (all.attributes.items) {
          attrs = all.attributes.items
        }

        return attrs.map(el => ({ attribute: el, filterWidgetType: el.filterWidgetType }))
      }

      return []
    },

    prepare(value) {
      this.prepared = value
    },

    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },

    handleInput(value) {
      this.prepare(value)
    },

    filter() {
      this.$emit('input', {
        ...this.value,
        filters: this.prepared,
        offset: 0,
      })
    },

    toggleFilter() {
      this.isActive = !this.isActive

      // toggleOverflow(this.isActive)
    },

    hideFilter() {
      this.isActive = false

      // toggleOverflow(this.isActive)
    },
  },
}
</script>
