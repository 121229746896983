<template lang="pug">
  include ./../../../mixins
  +b.form-content-wrapper
    +b.form-body--registration
      +b.i-preloader.--absolute(v-if="isLoading")
        +e.item
      +b.form-top-info--step-three {{ _("Реєстрація - крок 2") }}
      validation-observer(
        ref="validator"
        tag="form"
        class="form-element"
        @submit.prevent='prepareData'
        v-slot="{ valid, errors: globalErrors }"
      )
        validation-provider(
            tag="div"
            class="cabinet-avatar-vp"
            v-slot="{ errors }"
            name="avatar"
          )
          +b.cabinet-avatar-wrapper
            +e.img-wrapper
              +e.IMG.image(
                :src='formData.avatar'
              )
              +e.btn.btn-avatar(@click="$refs.uploadAvatar.click()")
                +b.I.icon.icon-pencil
            input(
              ref='uploadAvatar'
              class='upload-avatar'
              type="file"
              accept="image/*"
              @change="onChangeAvatar"
            )
            +e.title(
              @click="$refs.uploadAvatar.click()"
            ) {{ _("Завантажити фотографію") }}
            +b.P.help-type-error(v-if="errors[0]") {{ errors[0] }}
        +b.server-error(v-if="serverError") {{ serverError }}
        +b.form-inputs-wrap
          +b.form-inputs
            +b.form-inp-group
              +b.g-row--space_26
                +b.g-cell.g-cols--12-xs.--6-md.form-inp
                  +b.input_label {{ _("Ім'я*") }}
                  input-group(
                    rules='required',
                    type="text",
                    id="firstName",
                    name="firstName",
                    placeholder="Богдан",
                    v-model="formData['firstName']",
                    :class="{'is-error': showInputError(globalErrors, 'firstName')}",
                  ).input_style
                    template(v-slot:error="{errors}")
                      transition(name="fade")
                        span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--6-md.form-inp
                  +b.input_label {{ _("Прізвище*") }}
                  input-group(
                    rules='required',
                    type="text",
                    id="lastName",
                    name="lastName",
                    placeholder="Хмельницький",
                    v-model="formData['lastName']",
                    :class="{'is-error': showInputError(globalErrors, 'lastName')}",
                  ).input_style
                    template(v-slot:error="{errors}")
                      transition(name="fade")
                        span.help-type-error(v-if='errors.length') {{ errors[0] }}
              +b.g-row--space_26
                //- +b.g-cell.g-cols--12-xs.--6-md.form-inp
                //-   +b.input_label {{ _("Назва компанції*") }}
                //-   input-group(
                //-     rules='required',
                //-     type="text",
                //-     id="company",
                //-     name="company",
                //-     placeholder="Lightacademy",
                //-     v-model="formData['company']",
                //-     :class="{'is-error': showInputError(globalErrors, 'company')}",
                //-   ).input_style
                //-     template(v-slot:error="{errors}")
                //-       transition(name="fade")
                //-         span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--6-md.form-inp.multiselect-wrapper
                  +b.input_label {{ _("Компанія*") }}
                  //- validation-provider(
                  //-   v-slot="{ errors }"
                  //-   rules="required"
                  //-   name="company"
                  //- )
                  +b.form-inp
                    autocomplete-select.form-single-select(
                      v-model="formData.company"
                      :filter="autocomplete.company"
                      rules="required"
                      :required="true"
                      disabled=false
                      @input='getTradingTeams'
                      ref='company'
                    )
                      //- +b.P.help-type-error(v-if="errors[0]") {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--6-md.form-inp.multiselect-wrapper
                  +b.input_label {{ _("Торгова команда*") }}
                  //- validation-provider(
                  //-   v-slot="{ errors }"
                  //-   rules="required"
                  //-   name="team"
                  //- )
                  +b.form-inp
                    autocomplete-select.form-single-select(
                      v-model="formData.team"
                      :filter="autocomplete.team"
                      rules="required"
                      :required="true"
                      :disabled='!Boolean(formData.company)'
                      :params="{ company: formData.company ? formData.company.id : ''}"
                      ref='team'
                    )
                    //- +b.P.help-type-error(v-if="errors[0]") {{ errors[0] }}
              validation-provider(
                tag="div"
                v-slot="{ errors }"
                :rules="{ required: { allowFalse: false } }"
                name="agreement"
              ).control-checkbox-wrapper
                +b.LABEL.control-checkbox.--variant_flex
                  +e.INPUT.element(
                    type="checkbox"
                    v-model="formData.agreement"
                  )
                  +e.label
                  +e.content
                    span
                      span.form-title {{ _("Згоден із") }}
                      |&nbsp;
                      +b.A.form-link(
                        href="#"
                        target="_blank"
                      ) {{ _("політикою конфендинційності") }}
                      |&nbsp;
                      span.form-title {{ _("та") }}
                      |&nbsp;
                      +b.A.form-link(
                        href="#"
                        target="_blank"
                      ) {{ _("правилами сайта") }}
                +b.P.help-type-error(v-if="errors[0]") {{ errors[0] }}
              +b.form-btns-wrapper
                +b.g-row--space_26.--appearance_spaced
                  +b.g-cell.g-cols.--12-xs.--6-md
                    +b.BUTTON.btn-base--empty(
                        @click.prevent='goToFirstStepRegistration()'
                      ) {{ _("Назад") }}
                  +b.g-cell.g-cols.--12-xs.--6-md
                    +b.BUTTON.btn-base--blue(
                      type="submit",
                      @click.prevent='prepareData'
                    ) {{ _("Завершити") }}
      +b.form-links
        +b.SPAN.dark-blue-text.small-reg.lh-180 {{ _("Вже є акаунт? ") }}
        +b.A.small-reg.blue-text.hover-dark-blue.lh-180(:href="linkToLogin") {{ _("Авторизуватися") }}
    +b.form-image-wrapper--send-code
      +e.IMG.image(
          :src="'/static/img/registration_form_image.jpg'"
        )
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { getCookie } from '@utils/cookies'
import InfoModal from '@components/Modals/InfoModal'
import AutocompleteSelect from '@components/Filters/Widgets/Autocomplete'
import {
  registrationStepThreeResource,
  userSetAvatarResource,
  userDetailInfoResource,
  tradingTeamsListResource,
  companiesListResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  components: {
    AutocompleteSelect,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        company: '',
        team: '',
        avatar: '',
        agreement: false,
      },
      email: '',
      userId: '',
      serverError: '',
      redirectTo: '',
      linkToLogin: window.loginPageLink,
      isLoading: false,
      user: {},
      teams: [],
      companies: [],
      company: '',
      uncantChooseTeam: true,
      autocomplete: {
        team: {
          placeholder: `${this._('Команда')} *`,
          name: 'team',
          label: 'title',
          resource: tradingTeamsListResource,
        },
        company: {
          placeholder: `${this._('Компанія')} *`,
          name: 'company',
          label: 'title',
          resource: companiesListResource,
        },
      },
    }
  },
  async mounted() {
    this.email = getCookie('email')
    this.userId = getCookie('userId')
    // if (!this.email || !this.userId) {
    //   // якщо нема пошти, то повертаємо на перший крок реєстрації
    //  window.location = window.registrationPageLink
    // }
    await this.getUserInfo()
    await this.getCompanies()
    // await this.getTradingTeams()
  },
  // watch: {
  //   company: {
  //     immediate: true,
  //     async handler() {
  //       this.formData.team = ''
  //       this.teams = []
  //       if (this.formData.company) {
  //         this.uncantChooseTeam = true
  //         // await this.getTradingTeams()
  //         this.$refs.team.receiveWithParams()
  //         console.log(this.formData.company)
  //         console.log(this.company)
  //       } else {
  //         this.uncantChooseTeam = false
  //       }
  //     },
  //   },
  // },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        id: this.userId,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        // company: this.formData.company,
        companyUser: this.formData.company.id,
        team: this.formData.team.id,
      })
    },
    send(data) {
      this.isLoading = true

      return registrationStepThreeResource.execute({}, data).then(() => {
        this.$refs.validator.reset()
        // якщо все добре, то відкриваємо попап з інфою
        const title = this._('Дякуємо!')
        const information = 'Вас успішно зареєстровано!'
        this.redirectTo = window.cabinetPageLink
        this.$modal.show(
          InfoModal,
          {
            title,
            information,
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          },
          {
            'before-close': () => {
              window.location = this.redirectTo
            },
          }
        )
        // // Обнуляємо дані форми
        // this.resetData()
      }).finally(() => {
        this.isLoading = false
      })
    },
    // resetData() {
    //   this.formData = {
    //     code: '',
    //   }
    // },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        const [serverError] = parsed.nonFieldErrors
        this.serverError = serverError
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    async getUserInfo() {
      await userDetailInfoResource.execute(
        { pk: this.userId }
      ).then(({ data }) => {
        const { item } = data
        this.user = {
          isActive: item.isActive,
          finishFirstStepReg: item.finishFirstStepReg,
        }
        this.formData.avatar = item.avatar
        this.formData.firstName = item.firstName
        this.formData.lastName = item.lastName
        this.formData.company = item.company
        console.log(this.formData.company)
        console.log(this.company)
        this.formData.team = item.team
      })
    },
    async getTradingTeams() {
      this.formData.team = ''

      await this.$nextTick()

      if (this.formData.company) {
        this.uncantChooseTeam = true
        // await this.getTradingTeams()
        this.$refs.team.receiveWithParams()
        // console.log(this.formData.team)
      } else {
        this.uncantChooseTeam = false
      }
      // await tradingTeamsListResource.execute(
      //   { company_id: this.company.id }
      // ).then(({ data }) => {
      //   const { items } = data
      //   this.teams = items
      //   this.uncantChooseTeam = false
      // })
    },
    async getCompanies() {
      this.$refs.company.receiveWithParams()
      console.log(this.formData.company)
      // await companiesListResource.execute(
      //   {}
      // ).then(({ data }) => {
      //   const { items } = data
      //   this.companies = items
      // })
    },
    onChangeAvatar(e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      const fileName = file.name
      const promise = this.getBase64Image(url)
      this.isLoading = true
      promise.then(
        dataURL => userSetAvatarResource.execute(
          { pk: this.userId },
          { avatar: `${fileName};${dataURL}` }
        )
          .then(({ data }) => {
            const { item } = data
            this.formData.avatar = item.avatarUrl
          })
      );
      this.isLoading = false
    },
    getBase64Image(url) {
      const promise = new Promise(resolve => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const dataURL = canvas.toDataURL('image/png')
          resolve(dataURL)
        };
        img.src = url
      })
      return promise
    },
    goToUserPersonalPage() {
      window.location.pathname = window.cabinetPageLink
    },
    goToFirstStepRegistration() {
      window.location.pathname = window.registrationPageLink
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
