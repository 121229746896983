import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const FEEDBACK_CREATE_LINK = prefixAPI('/create/', 'business_requests')
const TYPES_OF_REQUESTS_LINK = prefixAPI('/types/{?page,}', 'business_requests')

export const feedbackCreateResource = createResource(
  FEEDBACK_CREATE_LINK, postResource
)
export const typesOfRequestsResource = createResource(
  TYPES_OF_REQUESTS_LINK
)
