<template lang="pug">
  include ./../../../mixins
  validation-provider(
    :name="$attrs.name"
    :rules="rules",
    :vid="$attrs.vid"
    v-slot="{ validate, errors }",
    tag="div"
  )
    slot
      +b.INPUT(
        :class="className"
        :value="value",
        :type="$attrs.type",
        ref='input',
        :disabled='$attrs.disabled',
        :placeholder="$attrs.placeholder",
        :id="$attrs.id",
        :min='$attrs.min',
        v-model="innerValue"
        @keyup="$emit('keyup')"
      )
    slot(name='error' :errors='errors')
      transition(name="fade")
        span.help-type-error(
          v-if='errors.length'
          :class="errorClass"
        ) {{ errors[0] }}

</template>
<script>
export default {
  inheritAttrs: false,
  name: 'InputGroup',
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
    },
    className: {
      type: String,
      default: '',
    },
    errorClass: {},
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>
