<script>

export default {
  props: {
    active: {
      default: 0,
    },
  },

  data() {
    return {
      activeItem: this.active,
    }
  },

  methods: {
    activate(item) {
      if (item === this.activeItem) {
        this.activeItem = null
      } else {
        this.activeItem = item
        this.$nextTick(() => {
          const el = document.querySelector(`[index="${this.activeItem}"]`)
          const headerOffset = 90
          const elementPosition = el.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.scrollY - headerOffset
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          })
        })
      }
    },
  },

  render() {
    return this.$scopedSlots.default({
      activeItem: this.activeItem,
      activate: this.activate,
    })
  },
}
</script>
