/* eslint-disable */
export function superagentJsonTransformer(response) {
  return response.body;
}

export function defaultDataTransformer(response) {
  return response.json().then(result => result)
}

export function simpleTransformer(response) {
  return response
}

export function fetcher(resource, init = {}) {
  return fetch(resource, init)
}

export function transformDataFetcher(f, transformer) {
  return function () {
    return f.apply(this, arguments).then(transformer)
  }
}

export function jsonTransformer(response) {
  return response.json()
}

export function raiseHttpErrorTransformer(response) {
  if (!response.ok) {
    throw response
  }
  return response
}
