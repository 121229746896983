import { createI18n } from '@/i18n'
import { createRouter } from './router'
import { createStoreObject } from './js/store'

export function createApp({ Vue, I18n, Router, Store }) {
  const i18n = createI18n({ Vue, I18n }) // підключення перекладів
  const router = createRouter({ Router }) // створення та підключення Роутера для ходження по фронтовим сторінкам-вьюхам
  const store = createStoreObject({ Vue, Store }) // підключення зберіговища для глобальних/спільних змінних між компонентами
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      }
    },
  })

  return { app, i18n, router, store }
}
