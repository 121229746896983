<template lang="pug">
  include ./../../../mixins
  +b.popup-wrapper
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title {{ _('Підтвердження') }}
    +b.P.popup-description {{ text }}
    +b.popup-btns
      +b.btn-base--blue.--txt-center.--half(
          @click="readMaterial()"
        ) {{ _('Так') }}
      +b.btn-base--empty.--txt-center.--half(
        @click='$emit("close")'
      ) {{ _('Ні') }}
</template>

<script>
import {
  readMaterialResource,
} from '@services/courses.service'

export default {
  props: {
    pk: {
      type: String,
    },
    isRead: {
      type: Boolean,
    },
    promise: {},
  },
  data() {
    return {
      text: '',
    }
  },
  mounted() {
    if (this.isRead) {
      this.text = this._('Ви дійсно бажаєте відмітити матеріал НЕ прочитаним?')
    } else {
      this.text = this._('Ви дійсно бажаєте відмітити матеріал прочитаним?')
    }
  },
  methods: {
    readMaterial() {
      return readMaterialResource.execute(
        { pk: this.pk },
        { isRead: !this.isRead }
      ).then(() => {
        this.promise(!this.isRead)
        this.$emit('close')
      })
    },
  },
}

</script>
