<template lang="pug">
  include ./../../../mixins
  +b.form-content-wrapper
    +b.form-body--registration
      +b.i-preloader.--absolute(v-if="isLoading")
        +e.item
      +b.form-top-info {{ _("Реєстрація - Крок 1") }}
      validation-observer(
        ref="validator"
        tag="form"
        class="form-element"
        @submit.prevent='prepareData'
        v-slot="{ valid, errors: globalErrors }"
      )
        +b.server-error(v-if="serverError") {{ serverError[0] }}
        +b.form-inputs-wrap
          +b.form-inputs
            +b.form-inp-group
              +b.form-inp
                +b.input_label {{ _("Електронна пошта*") }}
                input-group(
                  rules='required|email'
                  type="email"
                  id="email"
                  name="email"
                  placeholder="mail@gmail.com"
                  v-model="formData['email']"
                  :class="{'is-error': showInputError(globalErrors, 'email')}"
                ).input_style
                  template(v-slot:error="{errors}")
                    transition(name="fade")
                      span.help-type-error(v-if='errors.length') {{ errors[0] }}
              +b.g-row--space_26
                +b.g-cell.g-cols--12-xs.--6-md.form-inp
                  +b.relative
                    +b.input_label {{ _("Пароль*") }}
                    +b.I.icon.icon-password-secret.input_icon(
                      @click="switchVisibility(0)"
                      v-if="typeOfPassword[0] === 'password'"
                    )
                    +b.I.icon.icon-password-public.input_icon(
                      @click="switchVisibility(0)"
                      v-else
                    )
                    input-group(
                      rules='required|min:6',
                      :type='typeOfPassword[0]',
                      id="passwordOne",
                      name="passwordOne",
                      placeholder="•••••••••••••••",
                      v-model="formData['passwordOne']",
                      vid="passwordOne",
                      :class="{'is-error': showInputError(globalErrors, 'passwordOne')}",
                    ).input_style
                      template(v-slot:error="{errors}")
                        transition(name="fade")
                          span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--6-md.form-inp
                  +b.relative
                    +b.input_label {{ _("Повторіть пароль*") }}
                    +b.I.icon.icon-password-secret.input_icon(
                      @click="switchVisibility(1)"
                      v-if="typeOfPassword[1] === 'password'"
                    )
                    +b.I.icon.icon-password-public.input_icon(
                      @click="switchVisibility(1)"
                      v-else
                    )
                    input-group(
                      rules='required|confirmed:passwordOne',
                      :type='typeOfPassword[1]',
                      id="passwordTwo",
                      name="passwordTwo",
                      placeholder="•••••••••••••••",
                      v-model="formData['passwordTwo']",
                      :class="{'is-error': showInputError(globalErrors, 'passwordTwo')}",
                    ).input_style
                      template(v-slot:error="{errors}")
                        transition(name="fade")
                          span.help-type-error(v-if='errors.length') {{ errors[0] }}
        +b.form-button--register
          +b.BUTTON.btn-base--blue(
            type="submit",
            @click.prevent='prepareData'
          ) {{ _("Далі") }}
      +b.form-links
        +b.SPAN.dark-blue-text.small-reg.lh-180 {{ _("Вже є акаунт? ") }}
        +b.A.small-reg.blue-text.hover-dark-blue.lh-180(:href="linkToLogin") {{ _("Авторизуватися") }}
    +b.form-image-wrapper
      +e.IMG.image(
          :src="'/static/img/registration_form_image.jpg'"
        )
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { setCookie, getCookie } from '@utils/cookies'
import InfoModal from '@components/Modals/InfoModal'
import {
  registrationStepOneResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  data() {
    return {
      formData: {
        email: '',
        passwordOne: '',
        passwordTwo: '',
      },
      serverError: '',
      typeOfPassword: [
        'password',
        'password',
      ],
      redirectTo: '',
      linkToLogin: window.loginPageLink,
      isLoading: false,
    }
  },
  mounted() {
    this.formData.email = getCookie('email')
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        email: this.formData.email,
        passwordOne: this.formData.passwordOne,
        passwordTwo: this.formData.passwordTwo,
      })
    },
    send(sendData) {
      this.isLoading = true

      return registrationStepOneResource.execute(
        {},
        sendData
      ).then(({ data }) => {
        const { item } = data
        this.$refs.validator.reset()
        // якщо все добре, то відкриваємо попап з інфою
        setCookie('email', item.email)
        setCookie('userId', item.id)
        let title = ''
        let information = ''
        if (item.finish_first_step_reg) {
          title = this._('Увага!')
          information = 'Ви не завершили реєстрацію минулого разу.'
          this.redirectTo = window.registrationPageThreeLink
        } else {
          title = this._('Дякуємо!')
          information = 'На вказаний email було надіслано код для підтвердження реєстрації.'
          this.redirectTo = window.registrationPageTwoLink
        }
        this.$modal.show(
          InfoModal,
          {
            title,
            information,
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          },
          {
            'before-close': () => {
              window.location = this.redirectTo
            },
          }
        )
        // Обнуляємо дані форми
        this.resetData()
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        email: '',
        passwordOne: '',
        passwordTwo: '',
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    goToUserPersonalPage() {
      window.location.pathname = window.cabinetPageLink
    },
    switchVisibility(index) {
      this.typeOfPassword[index] = 'password' === this.typeOfPassword[index] ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
