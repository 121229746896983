import InputGroup from './InputGroup'
// import ModalTrigger from './ModalTrigger'
import SideBar from './SideBar'
import UiSlider from './UiSlider'
import ShareLinks from './ShareLinks'
import UiTabs from './UiTabs'
import CopyButton from './CopyButton'
import ModalTrigger from './ModalTrigger'

export default function Components(Vue) {
  Vue.component('InputGroup', InputGroup)
  // Vue.component('ModalTrigger', ModalTrigger)
  Vue.component('sidebar', SideBar)
  Vue.component('UiSlider', UiSlider)
  Vue.component('share-links', ShareLinks)
  Vue.component('ui-tabs', UiTabs)
  Vue.component('ui-copy-button', CopyButton)
  Vue.component('ui-modal-trigger', ModalTrigger)
}
