import {
  createResource,
  patchResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const READ_MATERIAL_LINK = prefixAPI('/materials/read/{pk}/', 'courses')
const START_COURSE_LINK = prefixAPI('/course/start/{pk}/', 'courses')
const FINISH_COURSE_LINK = prefixAPI('/course/finish/{pk}/', 'courses')

export const readMaterialResource = createResource(
  READ_MATERIAL_LINK, patchResource
)
export const startCourseResource = createResource(
  START_COURSE_LINK, patchResource
)
export const finishCourseResource = createResource(
  FINISH_COURSE_LINK, patchResource
)
