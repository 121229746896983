<script>
export default {
  name: 'ModalTrigger',

  props: {
    url: {
      type: String,
      default: () => null,
    },
    modalClasses: {
      type: String,
    },
  },

  methods: {
    openPopup() {
      const modal = () => import(`@components/${this.url}`)

      this.$modal.show(modal, {
        info: this.$attrs,
      }, {
        class: this.modalClasses,
        height: 'auto',
        width: 600,
        adaptive: true,
        scrollable: true,
        transition: 'false',
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      openPopup: this.openPopup,
    })
  },
}
</script>
