export function createStore() {
  return {
    namespaced: true,

    state: {
      sidebarIsCollapsed: JSON.parse(localStorage.getItem('sidebarIsCollapsed')),
    },

    actions: {
      async updateSidebarIsCollapsed({ commit }, data) {
        commit('CHANGE_SIDEBAR_IS_COLLAPSED', data)
      },
    },

    mutations: {
      CHANGE_SIDEBAR_IS_COLLAPSED(state, result) {
        state.sidebarIsCollapsed = result
        localStorage.setItem('sidebarIsCollapsed', result)
      },
    },
  }
}
