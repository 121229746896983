<template lang="pug">
  include ./../../../mixins
  Slick(ref="slick"
    @init='emmiter'
    @reInit="handleReInit"
    :options="options"
    :class='"default-slider"')
    slot(name='slide')
</template>
<script>
import Slick from 'vue-slick'

export default {
  name: 'UiSlider',
  components: {
    Slick,
  },
  props: {
    options: {},
    reiniter: {},
  },
  watch: {
    reiniter() {
      this.reInit()
    },
  },
  mounted() {
    if (this.reiniter) {
      const time = 1250
      this.reInit()
      setTimeout(() => {
        this.reInit()
      }, time)
    }
  },
  methods: {
    next() {
      this.$refs.slick.next()
    },
    prev() {
      this.$refs.slick.prev()
    },
    emmiter(event, slick) {
      this.$emit('init', slick)
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$refs.slick.destroy()

      this.$nextTick(() => {
        this.$refs.slick.create()
      })
    },
    handleReInit() {
      // console.log(event, slick)
    },
  },
}
</script>
