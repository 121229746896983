<template lang="pug">
  include ./../../../mixins
  +b.popup-wrapper
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title(v-if='title') {{ title }}
    +b.P.popup-description(v-if='information') {{ information }}
    +b.P.popup-description(v-for='(item, index) in informationList') {{ informationList[index] }}
    +b.form-button--info-popup
      +b.BUTTON.btn-base--empty(
        v-if="!btnLink"
        type="button" @click='$emit("close")'
      ) {{ btnTitleVal }}
      +b.A.btn-base--empty(
        v-else
        :href="btnLink"
      ) {{ btnTitleVal }}
</template>

<script>

export default {
  props: {
    info: {},
    title: {},
    information: {},
    informationList: [],
    btnTitle: {},
    btnLink: {},
    timeout: Number,
  },
  data() {
    return {
      btnTitleVal: this._('Добре'),
    }
  },
  mounted() {
    if (this.info) {
      if (this.info.btntitle) {
        this.btnTitleVal = this.info.btntitle
      }
      if (this.info.title) {
        this.title = this.info.title
      }
      if (this.info.btnlink) {
        this.btnLink = this.info.btnlink
      }
    } else if (this.btnTitle) {
      this.btnTitleVal = this.btnTitle
    }
    if (this.timeout) {
      setTimeout(() => {
        this.$emit('close')
      }, this.timeout)
    }
  },
}

</script>
