import Base from './components/Base'
import uiElements from './components/UIElements'
import UserComponents from './components/Users'
import FilterComponents from './components/Filters'
import BusinessRequests from './components/BusinessRequests'
import Faqs from './components/Faqs'
import Courses from './components/Courses'
import Tests from './components/Tests'
import Icons from './components/Icons'

// реєструємо для глобальної видимості та використання компонентів
export default function Components(Vue) {
  Vue.use(Base)
  Vue.use(uiElements)
  Vue.use(UserComponents)
  Vue.use(FilterComponents)
  Vue.use(BusinessRequests)
  Vue.use(Faqs)
  Vue.use(Courses)
  Vue.use(Tests)
  Vue.use(Icons)
}
