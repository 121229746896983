<template lang="pug">
  include ./../../../mixins
  +b.user-control-wrapper
    +b.user-control-text
      +e.title {{ _("Пароль") }}
      +e.SPAN.description •••••••••••••••
    +b.BUTTON.btn-base--empty.--txt-center.--fit-content.--flex(
      @click.prevent='openModal()'
    )
      +e.I.icon.icon-pencil
      +e.SPAN.title {{ _("Редагувати") }}
</template>

<script>
import ChangePasswordModal from '@components/Modals/ChangePasswordModal'
import InfoModal from '@components/Modals/InfoModal'

export default {
  data() {
    return {
      successChange: false,
    }
  },
  watch: {
    successChange: {
      immediate: true,
      handler() {
        if (this.successChange) {
          this.showInfoModal()
        }
      },
    },
  },
  methods: {
    async openModal() {
      await this.openModalPromise(this)
    },
    async openModalPromise() {
      return new Promise(resolve => {
        /**
         * @argument {boolean} arg
         */
        const promise = arg => new Promise(res => {
          if (arg) {
            res(arg)
          }
        }).then(result => {
          resolve(result)
          // якщо перший модал - повернув "успіх" (нову пошту)
          // відкриваємо інформаційний модал
          if (result) {
            this.successChange = true
          }
        })
        this.$modal.show(
          ChangePasswordModal,
          {
            promise,
          },
          {
            height: 'auto',
            width: 344,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          }
        )
      })
    },
    showInfoModal() {
      const title = this._('Вітаємо!')
      const information = 'Пароль успішно змінено!'
      this.$modal.show(
        InfoModal,
        {
          title,
          information,
        },
        {
          height: 'auto',
          width: 360,
          adaptive: true,
          scrollable: true,
          transition: 'false',
        },
        {
          'before-close': () => {
            window.location.reload()
          },
        }
      )
    },
  },
}
</script>
