<template lang="pug">
  include ./../../../mixins
  +b.start-course-btn-wrapper(
    v-if='percent >= 100 && status === "progress"'
  )
    +b.BUTTON.btn-base--blue.--txt-center.--blink(
      @click='setCourseStatus()'
    ) {{ btnTitle }}
    +b.course-help-text {{ _('Увага! Після завершення курса на проходження тестів буде відведено 48 годин') }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import { getCommonParsedErrors } from '@mixins/SendMixin'
import {
  finishCourseResource,
} from '@services/courses.service'

export default {
  props: {
    pk: {
      type: String,
    },
    status: {
      type: String,
    },
    percent: {
      type: String,
    },
  },
  data() {
    return {
      btnTitle: this._('Завершити'),
    }
  },
  // mounted() {
  //   console.log(this.percent)
  // },
  methods: {
    async setCourseStatus() {
      finishCourseResource.execute(
        { pk: this.pk },
        { status: 'finished' }
      ).then(() => {
        // console.log(data)
        window.location.reload()
      }).catch(async error => {
        const parsed = await getCommonParsedErrors(error)

        if (parsed && parsed.status) {
          const [firstItem] = parsed.status
          const { message } = firstItem

          this.$modal.show(
            InfoModal,
            {
              title: this._('Увага'),
              information: message,
            },
            {
              height: 'auto',
              width: 360,
              adaptive: true,
              scrollable: false,
              transition: 'false',
            },
            {
              'before-close': () => {
                window.location.reload()
              },
            }
          )
        }
      })
    },
  },
}
</script>
