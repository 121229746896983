<template lang="pug">
  include /mixins
  ui-click-outside(:do="hide")
    +b.range-slider
      +e.heading(@click.prevent="open")
        +b.multiselect.form-single-select(:class="{'multiselect--active': isOpen}")
          +e.select
          +e.tags
            +e.single
              +e.placeholder {{ filter.placeholder }}
                span(v-if="price.min || price.max") :&nbsp;
                span(v-if="price.min") {{ _("от") }} {{ price.min }} {{ currentCurrencySymbol }} &nbsp;
                span(v-if="price.max") {{ _("до") }} {{ price.max }} {{ currentCurrencySymbol }}
      +e.content(:class="{'is-active': isOpen}")
        +b.g-row.--appearance_spaced.--space_xs(v-if="filter.currencies.length")
          +b.g-cell(v-for="item in filter.currencies")
            +b.i-tag.--variant_1(
              @click.prevent="selectCurrency(item.slug)"
              :class="{'is-active': selectedCurrency === item.slug}"
            )
              +e.SPAN.content {{ item.label }}
        +b.ds-panel.--space_md
          +e.element.--offset_top
            +b.g-row.--align_center.--space_xs
              +b.g-cell.g-cols.--auto
                +b.input_style.--variant_default.--size_xs
                  input(
                    v-model="price.min"
                    :placeholder="placeholder.min"
                    @input="inputDebounceFn();clearRange()"
                  )
              +b.g-cell.g-cols.--narrow
                +b.SPAN.ds-caption.--size_sm.--color_gray -
              +b.g-cell.g-cols.--auto
                +b.input_style.--variant_default.--size_xs
                  input(
                    v-model="price.max"
                    :placeholder="placeholder.max"
                    @input="inputDebounceFn();clearRange()"
                  )
        +b.ds-panel.--space_xl
          +e.element.--offset_top
            +b.g-row.--appearance_spaced.--space_sm
              +b.g-cell.g-cols.--12-xs(v-for="(item, index) in filter.items")
                +b.LABEL.control-checkbox.--variant_flex(@click="checkRange(item)")
                  +e.INPUT.element(
                    type="radio"
                    :value="item.map(a => a.value)"
                    v-model="range"
                    @change="selectRange"
                  )
                  +e.label
                  +e.SPAN.content {{ item.map(a => a.title).filter(a => a).join(' - ') }}
</template>

<script>
import { debounce, isEqual } from 'lodash'

export default {
  name: 'price-widget',

  props: {
    value: {},
    filter: {},
  },

  data() {
    return {
      selectedCurrency: 'sterling',
      price: {
        min: null,
        max: null,
      },
      isOpen: false,
      inputDebounceFn: null,
      placeholder: {
        min: this._('від'),
        max: this._('до'),
      },
      range: [null, null],
      oldRange: [null, null],
    }
  },

  computed: {
    currentCurrencySymbol() {
      return this.filter.currencies.find(el => el.slug === this.selectedCurrency).label
    },
  },

  watch: {
    value: { immediate: true, handler: 'normalizeFrom' },
  },

  mounted() {
    const timeout = 500
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.inputDebounceFn = debounce(this.input, timeout)
  },

  methods: {
    hide() {
      this.isOpen = false
    },

    open() {
      this.isOpen = true
    },

    normalizeFrom() {
      if (!this.value) {
        this.price.min = null
        this.price.max = null
        this.range = [null, null]

        return
      }

      const { currency, min = null, max = null } = this.value

      this.range = [min, max]
      this.price.min = min
      this.price.max = max

      const option = this.filter.currencies.find(item => item.slug === currency)

      if (option) {
        this.selectedCurrency = option.slug
      }
    },

    selectCurrency(currency) {
      this.selectedCurrency = currency
      this.inputDebounceFn()
    },

    clearRange() {
      this.range = [null, null]
    },

    clearPrice() {
      this.price.min = null
      this.price.max = null
    },

    checkRange(value) {
      const val = value.map(item => item.value)

      if (isEqual(val, this.oldRange)) {
        this.clearRange()
        this.clearPrice()
        this.oldRange = [null, null]
      } else {
        this.oldRange = value
      }

      this.inputDebounceFn()
    },

    selectRange() {
      const [min, max] = this.range

      this.price.min = min

      if (Number.isInteger(max)) {
        this.price.max = max
      } else {
        this.price.max = null
      }

      this.inputDebounceFn()
    },

    input() {
      const formdata = {
        range: [Number(this.price.min), Number(this.price.max)],
        currency: this.selectedCurrency,
      }

      this.$emit('input', formdata)
    },
  },
};

</script>
