<template lang="pug">
  include ./../../../mixins
  +b.questions-list-wrapper
    +b.g-row--space_20.--appearance_spaced.questions-lists
      +b.g-cell.g-cols--12-xs.--6-lg.--12-xl.--6-mac.question-wrapper(
        v-for="(question, questionIndex) in questions"
        :class="{'image': question.image}"
      )
        +b.question-text-content
          +e.title {{ question.text }}
          +b.answers-list
            +b.answer-item(
              v-for="(answer, answerIndex) in question.answers"
            )
              +b.LABEL.control-radio.--variant_flex(
                @click="addAnswer(questionIndex, answer.id)"
              )
                +e.INPUT.element(
                  type="radio"
                  :name='question.id'
                )
                +e.label
                +e.SPAN.content.answer-content {{ answer.text }}
        +b.question-image-wrapper(
          v-if='question.image'
        )
          +e.IMG.image(
            :src='question.image'
            :alt='question.text'
            :title='question.text'
            loading="lazy"
          )
    +e.DIV.btn-wrapper
      +b.BUTTON.btn-base--blue(
        @click.prevent='finishTest'
        :class="{'disabled': answersCount!=questions.length}"
      ) {{ _("Відповісти") }}

</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import Confirm from '@components/Modals/Confirm'
import { getCommonParsedErrors } from '@mixins/SendMixin'
import {
  startTestResource,
  finishTestResource,
  testOfUserResource,
  questionsOfTestResource,
} from '@services/tests.service'

export default {
  props: {
    testId: {
      type: String,
    },
  },
  data() {
    return {
      isLoad: false,
      result: '',
      questions: '',
      questionsWithAnswers: [],
      answersCount: 0,
      idTestOfUser: '',
      statusOfTest: '',
      formdata: {},
    }
  },
  async mounted() {
    await this.getTestOfUser()
    await this.getQuestions()
  },
  methods: {
    async openModalPromise() {
      return new Promise(resolve => {
        /**
         * @argument {boolean} arg
         */
        const promise = arg => new Promise(res => {
          if (arg) {
            res(arg)
          }
        }).then(result => {
          resolve(result)
          // console.log(result)
          if ('true' === result) {
            startTestResource.execute(
              {},
              {
                id: this.testId,
                testOfUserId: this.idTestOfUser,
              }
            ).then(() => {
              // const { data } = res
              // console.log(data)
              this.statusOfTest = 'progress'
            }).catch(async error => {
              const parsed = await getCommonParsedErrors(error)

              if (parsed && parsed.id) {
                const [firstItem] = parsed.id
                const { message } = firstItem

                this.$modal.show(
                  InfoModal,
                  {
                    title: this._('Увага'),
                    information: message,
                  },
                  {
                    height: 'auto',
                    width: 360,
                    adaptive: true,
                    scrollable: false,
                    transition: 'false',
                  },
                  {
                    'before-close': () => {
                      window.location = window.testsPageLink
                    },
                  }
                )
              }
            })
          } else {
            window.location = window.testsPageLink
          }
        })
        this.$modal.show(
          Confirm,
          {
            promise,
            info: {
              title: this._('Бажаєте почати тест?'),
            },
          },
          {
            height: 'auto',
            width: 500,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          }
        )
      })
    },
    async getTestOfUser() {
      this.isLoad = true

      const params = {
        pk: this.testId,
      }

      await testOfUserResource.execute(
        params
      ).then(res => {
        const { data } = res
        // console.log(data)
        if (data && data.item) {
          // TODO смотреть на статус теста
          this.idTestOfUser = data.item.id
          this.statusOfTest = data.item.status
          // console.log(this.statusOfTest)
          if ('unavailable' === this.statusOfTest) {
            // console.log('открываем попап с кнопкой для перехода на список тестов')
            this.$modal.show(
              InfoModal,
              {
                title: this._('Увага'),
                information: this._('Тест не доступен або застарів'),
                btnTitle: this._('До списку тестів'),
              },
              {
                height: 'auto',
                width: 360,
                adaptive: true,
                scrollable: false,
                transition: 'false',
              },
              {
                'before-close': () => {
                  window.location = window.testsPageLink
                },
              }
            )
          } else if ('progress' !== this.statusOfTest) {
            // console.log('открываем попап с кнопкой для начала теста')
            this.openModalPromise()
          }
        }
        // console.log(this.idTestOfUser)
        this.isLoad = false
      })
    },
    async getQuestions() {
      this.isLoad = true

      const params = {
        pk: this.testId,
      }

      await questionsOfTestResource.execute(
        params
      ).then(res => {
        const { data } = res
        // console.log(data.items)
        if (data && data.items) {
          this.questions = data.items
          // console.log(this.questions)
          this.questions.forEach(
            question => {
              // console.log(question)
              this.questionsWithAnswers.push(
                {
                  question: question.id,
                  answer: null,
                }
              )
            }
          )
        }
        // console.log(this.questionsWithAnswers)
        this.isLoad = false
      })
    },
    async finishTest() {
      this.isLoad = true

      const params = {
        idOfTest: this.idTestOfUser,
        answers: this.questionsWithAnswers,
      }

      await finishTestResource.execute(
        {},
        params
      ).then(res => {
        const { data } = res
        const popupTitle = this._('Результати тестування')
        const btnTitle = this._('Продовжити')
        const informationList = []
        if (data && data.item) {
          // TODO отобразить результаті в попапе
          const { item } = data
          // console.log(item)
          const { status } = item
          if ('failed' === status) {
            informationList.push(this._('Тест не пройдено'))
            informationList.push(this._('Дякуємо за спробу!'))
          } else {
            informationList.push(this._('Тест пройдено'))
            informationList.push(this._('Вітаємо!'))
          }
          informationList.push(`Тест здано на ${item.percentRightQuestions} %.`)
          informationList.push(` Вірно ${item.rightQuestions} відповідей із ${parseInt(item.countQuestions, 10)}`)
          this.$modal.show(
            InfoModal,
            {
              title: popupTitle,
              informationList,
              btnTitle,
            },
            {
              height: 'auto',
              width: 360,
              adaptive: true,
              scrollable: false,
              transition: 'false',
            },
            {
              'before-close': () => {
                window.location = window.testsResultsPageLink
              },
            }
          )
        }
      })
      this.isLoad = false
    },
    addAnswer(questionIndex, answerId) {
      if (!this.questionsWithAnswers[questionIndex].answer) {
        this.answersCount += 1
      }
      this.questionsWithAnswers[questionIndex].answer = answerId
      // console.log(this.questionsWithAnswers)
      // console.log(this.answersCount)
    },
  },
}
</script>
