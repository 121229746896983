<template lang="pug">
  include /mixins
  +b.popup-wrapper
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title(v-if="info.title") {{ info.title }}
    +b.P.popup-description(v-if="info.text")
    +b.popup-btns
      +b.btn-base--blue.--txt-center.--half.--blink(
        @click.prevent="submit('true')"
      ) {{ _('Так') }}
      +b.btn-base--empty.--txt-center.--half(
        @click.prevent="submit('false')"
      ) {{ _('Ні') }}

</template>

<script>
export default {
  name: 'ConfirmModal',

  props: {
    info: {},
    promise: {},
  },

  methods: {
    submit(trigger) {
      this.promise(trigger)
      this.$emit('close')
    },
  },
}
</script>
