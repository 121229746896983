import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import {
  required, confirmed, min, min_value as minValue, max,
  integer, alpha, alpha_spaces as alphaSpaces, email, length,
} from 'vee-validate/dist/rules'

/* eslint-disable */
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule])
}

export const createValidationRules = app => {
  extend('phone', {
    validate(value) {
      // Regex for a international phone number
      const MOBILEREG = /^[+()\d-]+$/
      return MOBILEREG.test(value)
    },
    message() {
      return app._('Будь ласка введiть валiдний номер телефону')
    },
  })

  extend('ru-phone', {
    validate(value) {
      // Regex for a international phone number
      const MOBILEREG = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm
      return MOBILEREG.test(value)
    },
  })

  extend('one-lowercase', {
    validate(value) {
      const customRegex = new RegExp('(?=.*[a-z])')
      return customRegex.test(value)
    },
  })
  extend('one-uppercase', {
    validate(value) {
      const customRegex = new RegExp('(?=.*[A-Z])')
      return customRegex.test(value)
    },
  })
  extend('one-numeric', {
    validate(value) {
      const customRegex = new RegExp('(?=.*[0-9])')
      return customRegex.test(value)
    },
    message() {
      return app._('Введіть число')
    },
  })
  extend('must-be-true', {
    validate(value) {
      if (!value) {
        return false
      }
      return true
    },
    message() {
      return app._('Необхідно підтвердити')
    },
  })

  extend('arrayLengthMin', {
    validate(value, args) {
      const isNotDeletedItems = value.reduce((acc, el) => {
        if (el && !el.Delete) acc++
        return acc
      }, 0)
      return isNotDeletedItems >= args[0]
    },
    message(value, sec) {
      return `${app._('Минимальное количество -')} ${sec[0]}`
    },
  })

  extend('required', { ...required, message: app._("Поле обов'язкове до заповнення") });
  extend('email', { ...email, message: app._('Будь ласка введiть валiдний адрес пошти') });
  extend('integer', { ...integer, message: app._('Введіть ціле число') });
  extend('alpha', { ...alpha, message: app._('Тiльки лiтери') });
  extend('alpha_spaces', { ...alphaSpaces, message: app._('Тiльки лiтери') });
  extend('min', { ...min, message: (e, a) => `${app._('Введiть мiнiмум')} ${a.length} ${app._('символи')}` })
  extend('min_value', { ...minValue, message: (e, a) => `${app._('Введiть мiнiмум')} ${a.min} ${app._('символи')}` })
  extend('max', { ...max, message: app._('Введене значення перевищує допустимий обсяг') }),
  extend('confirmed', { ...confirmed, message: app._('Введені паролі мають збігатися') })
  extend('length', { ...length, message: (e, a) => `${app._('Значення має складатися із')} ${a.length} ${app._('символів')}` })
}
