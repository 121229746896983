import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Router from 'vue-router'
import { Store } from 'vuex'

import Multiselect from 'vue-multiselect'
import VModal from 'vue-js-modal'
import VueObserveVisibility from 'vue-observe-visibility'
import ResourceComponents from '@resource'

import { install } from '@/install'
import { createApp } from '@/app'
import appComponents from './js'

import { createValidationRules } from './js/validation/extends'
import './js/validation'
import './js/utils/index'
import './js/directives'
// import filters from '@/js/utils/filters'

import './styles/index.sass'

Vue.config.productionTip = false
Vue.use(install)
Vue.use(appComponents)
Vue.use(ResourceComponents)
Vue.use(VueObserveVisibility)
Vue.component('multiselect', Multiselect)
Vue.use(VModal, { dynamic: true, dialog: true })

const { app } = createApp({
  Vue,
  I18n,
  Router,
  Store,
})

app.$mount('#app')

createValidationRules(app)
