<template lang="pug">
  include ./../../../mixins
  +b.test-btn-wrapper(
    :class="{ 'blink': showBlink && needHighlight }"
  )
    +b.btn-base--empty.--txt-center(
      v-if="mode === 1"
      @click='sendRequest()'
    ) {{ btnTitle }}
    +b.A.btn-base--empty.--txt-center(
      v-if="mode === 2"
      :href='link'
    ) {{ btnTitle }}
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import { getCommonParsedErrors } from '@mixins/SendMixin'
import {
  requestToRestartTestResource,
} from '@services/tests.service'

export default {
  props: {
    pk: {
      type: String,
    },
    finished: {
      type: String,
    },
    status: {
      type: String,
    },
    link: {
      type: String,
    },
    needHighlight: {
      type: Boolean,
    },
  },
  data() {
    return {
      btnTitle: '',
      mode: 1,
      showBlink: false,
    }
  },
  mounted() {
    if ('False' === this.finished) {
      if ('wait' === this.status || 'progress' === this.status) {
        this.btnTitle = this._('Пройти')
        this.showBlink = true
      } else {
        this.btnTitle = this._('Перепройти')
      }
      this.mode = 2
    } else if ('True' === this.finished) {
      this.btnTitle = this._('Запит')
    }
    // if ('False' === this.status) {
    //   this.btnTitle = this._('Перепройти')
    //   this.mode = 2
    // } else if ('True' === this.status) {
    //   this.btnTitle = this._('Запит')
    // }
    // console.log(this.btnTitle)
  },
  methods: {
    async sendRequest() {
      requestToRestartTestResource.execute(
        {},
        { test: this.pk }
      ).then(data => {
        console.log(data)
        this.$modal.show(
          InfoModal,
          {
            title: this._('Дякуємо!'),
            information: this._('Ваш запит на повторне проходження теста надіслано'),
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: false,
            transition: 'false',
          }
        )
      }).catch(async error => {
        const parsed = await getCommonParsedErrors(error)

        if (parsed && parsed.test) {
          const [firstItem] = parsed.test
          const { message } = firstItem

          this.$modal.show(
            InfoModal,
            {
              title: this._('Увага'),
              information: message,
            },
            {
              height: 'auto',
              width: 360,
              adaptive: true,
              scrollable: false,
              transition: 'false',
            }
          )
        }
      })
    },
  },
}
</script>
