import { isEmpty } from 'lodash'

import RangeWidget from '../Widgets/Range'
import SelectWidget from '../Widgets/Select'
import MultiselectWidget from '../Widgets/Multiselect'
import SearchWidget from '../Widgets/Search'
import PriceWidget from '../Widgets/Price'

const WIDGETS_MAP = {
  select: SelectWidget,
  checkbox: MultiselectWidget,
  range: RangeWidget,
  input: SearchWidget,
  price: PriceWidget,
}

const rangeKeys = {
  0: 'min',
  1: 'max',
}

export default {
  widgetsMap: WIDGETS_MAP,

  props: {
    dynamicFilters: {},
    value: {},
    needSearch: {},
    needPrice: {},
    searchFilter: {},
    priceFilter: {},
  },

  computed: {
    preparedValue() {
      const localValues = JSON.parse(JSON.stringify(this.value))
      const valuesKeys = Object.keys(localValues)

      return valuesKeys.reduce((acc, key) => {
        if (key.includes('price')) {
          if (!acc.price) {
            acc.price = {
              currency: key.replace('_price_min', '').replace('_price_max', ''),
            }
          }

          const val = Array.isArray(localValues[key]) ? localValues[key][0] : localValues[key]

          if (key.includes('min')) {
            acc.price.min = val
          }

          if (key.includes('max')) {
            acc.price.max = val
          }
        } else if (key.includes('min') || key.includes('max')) {
          Object.keys(rangeKeys).forEach(index => {
            if (key.includes(rangeKeys[index])) {
              const clearKey = key.replace(`_${rangeKeys[index]}`, '')

              if (!acc[clearKey]) {
                acc[clearKey] = []
              }

              acc[clearKey][index] = localValues[key]
            }
          })
        } else {
          acc[key] = localValues[key]
        }

        return acc
      }, {})
    },

    filterIsEmpty() {
      return isEmpty(this.preparedValue)
    },
  },

  methods: {
    filterHandler(value, filter) {
      const eventMap = {
        range: this.updateMultipleValues,
        price: this.updatePriceValues,
      }
      const event = eventMap[filter.filterWidgetType] || this.updateValue

      event(value, filter.name || filter?.attribute?.props?.label)
    },

    updatePriceValues(val) {
      const res = {}
      const [min, max] = val.range
      const fromKey = `${val.currency}_price_min`
      const toKey = `${val.currency}_price_max`

      if (this.value) {
        Object.keys(this.value).forEach(k => {
          if (k.includes('_price_')) {
            delete this.value[k]
          }
        })
      }

      if (min) {
        res[fromKey] = min
      } else {
        delete this.value[fromKey]
      }

      if (max) {
        res[toKey] = max
      } else {
        delete this.value[toKey]
      }

      this.$emit('input', { ...this.value, ...res })
    },

    updateValue(value, key) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    updateMultipleValues(values, key) {
      const res = {}
      const [min, max] = values
      const fromKey = `${key}_min`
      const toKey = `${key}_max`

      if (min) {
        res[fromKey] = min
      } else {
        delete this.value[fromKey]
      }

      if (max) {
        res[toKey] = max
      } else {
        delete this.value[toKey]
      }

      this.$emit('input', { ...this.value, ...res })
    },
  },
}
