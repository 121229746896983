/* eslint-disable */

import {
  goToTop,
  scrolledHeader,
} from './ui-scripts.js'

document.addEventListener('DOMContentLoaded', e => {
  goToTop()

  window.addEventListener('scroll', () => {
    goToTop()
    scrolledHeader()
  })

  if (document.getElementsByClassName('go-to-top')) {
    let anchorLinks = Array.from(document.getElementsByClassName('go-to-top'))
    let sliceHash = 1
    let time = 0
    anchorLinks.forEach(el => {
      let link = el.getAttribute("href").slice(sliceHash)
      let item = document.getElementById(link)
      if (null != item) {
        el.addEventListener('click', (e) => {
          e.preventDefault()
          document.documentElement.scrollTop = time
        })
      }
    })
  }

  const blurSite = document.getElementsByClassName('blur-site')[0]
  const userMobileMenu = document.getElementsByClassName('user-mobile-icon-wrapper')[0]
  const togglerBtn = document.getElementById('togle-icon');
  const togglerSidebarMobileBtn = document.getElementById('togler-sidebar-mobile-btn');


  blurSite.addEventListener('click', () => {
    blurSite.classList.remove('visible')
    document.querySelector('body').classList.remove('hide-scroll');
    togglerBtn.classList.remove('open')
    togglerSidebarMobileBtn.classList.remove('open')
    togglerSidebarMobileBtn.classList.remove('hide')
    document.querySelector('.sidebar-mobile-wrapper').classList.remove('is-open');
    userMobileMenu.classList.remove('open')
  })

  // Логіка роботи бургер-кнопки у десктопному хедері
  
  // console.log('togglerBtn desktop')
  // console.log(togglerBtn)
  togglerBtn.addEventListener('click', () => {
    togglerBtn.classList.toggle('open')
    document.querySelector('.js-sidebar').classList.toggle('is-active')
    // console.log('test')
    // document.querySelector('sidebar-mobile-wrapper').classList.toggle('is-open');
    // document.querySelector('body').classList.toggle('hide-scroll');
  })

  // console.log('togglerSidebarMobileBtn desktop')
  // console.log(togglerSidebarMobileBtn)
  togglerSidebarMobileBtn.addEventListener('click', () => {
    if (togglerSidebarMobileBtn.classList.contains('open')) {
      blurSite.classList.remove('visible')
    } else {
      blurSite.classList.add('visible')
    }
    togglerSidebarMobileBtn.classList.toggle('open')
    togglerSidebarMobileBtn.classList.toggle('hide')
    // document.querySelector('.js-sidebar').classList.toggle('is-active')
    // console.log('test')
    document.querySelector('.sidebar-mobile-wrapper').classList.toggle('is-open');
    document.querySelector('body').classList.toggle('hide-scroll');
    userMobileMenu.classList.remove('open')
  })

  // console.log('userMobileMenu desktop')
  // console.log(userMobileMenu)
  userMobileMenu.addEventListener('click', () => {
    if (userMobileMenu.classList.contains('open')) {
      blurSite.classList.remove('visible')
    } else {
      blurSite.classList.add('visible')
    }
    userMobileMenu.classList.toggle('open')
    // blurSite.classList.toggle('visible')
    togglerSidebarMobileBtn.classList.remove('open')
    togglerSidebarMobileBtn.classList.remove('hide')
    document.querySelector('.sidebar-mobile-wrapper').classList.remove('is-open');
    document.querySelector('body').classList.remove('hide-scroll');
    // document.querySelector('body').classList.toggle('hide-scroll');
  })

  // const togglerSidebarMobileBtnClose = document.getElementById('test');
  // console.log('togglerSidebarMobileBtnClose desktop')
  // console.log(togglerSidebarMobileBtnClose)
  // togglerSidebarMobileBtnClose.addEventListener('click', () => {
  //   console.log('test togglerSidebarMobileBtnClose')
  //   togglerSidebarMobileBtnClose.classList.toggle('open')
  //   togglerSidebarMobileBtn.classList.toggle('hide')
  //   document.querySelector('.js-sidebar').classList.toggle('is-active')
  //   document.querySelector('.sidebar-mobile-wrapper').classList.toggle('is-open');
  //   document.querySelector('body').classList.toggle('hide-scroll');
  // })
  // console.log(getEventListeners(togglerSidebarMobileBtnClose))

  const topMenus = Array.from(document.getElementsByClassName('top-menu'));
  topMenus.forEach(el => {
    el.addEventListener('click', () => {
      el.classList.toggle('is-open')
    })
  })


})
