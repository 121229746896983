<template lang="pug">
  include ./../../../mixins
  +b.popup-wrapper
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title {{ _('Увага!') }}
    +b.P.popup-description {{ _('Ви дійсно бажаєте вийти?') }}
    +b.popup-btns
      +b.btn-base--blue.--txt-center.--half(
          @click="logout()"
        ) {{ _('Так') }}
      +b.btn-base--empty.--txt-center.--half(
        @click='$emit("close")'
      ) {{ _('Ні') }}
</template>

<script>
import {
  logoutUserResource,
} from '@services/users.service'

export default {
  data() {
    return {}
  },
  methods: {
    logout() {
      return logoutUserResource.execute(
        {},
        {}
      ).then(() => {
        // редірект на стр профіля
        window.location = window.loginPageLink
      })
    },
  },
}

</script>
