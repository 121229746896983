<template lang="pug">
  include /mixins
  multiselect.form-single-select(
    v-model="checked"
    track-by="label"
    open-direction="bottom"
    :class="{'form-multiple-select': multiple}"
    :multiple="multiple",
    :options="filterValues || []"
    label="title"
    :name="filter.name"
    :disabled="disabled"
    :searchable="searchable"
    :close-on-select="closeOnSelect"
    :max-height="180"
    :placeholder="needDisplayPlaceholder ? (filter.caption ? filter.caption : _('Все')) : ''"
    @input="submit"
  )
    template(slot="noResult" slot-scope="{}")
      span {{ _("Элементы не найдены") }}
    template(slot="noOptions" slot-scope="{}")
      span {{ _("Список пуст") }}
    template(
      slot="selection"
      slot-scope="{ values, search }"
      v-if="values.length"
    )
      +b.SPAN.multiselect__single
        | {{ values.map(el => el.title).join(', ') }}
      +b.i-count.--variant_absolute
        +e.content {{ values.length }}
    template(
      v-if="multiple"
      slot="option"
      slot-scope="{ option }"
    )
      +b.multiselect__child {{ option.title }}
</template>

<script>
export default {
  name: 'select-widget',

  props: {
    value: {},
    filter: {},
    disabled: {
      default: false,
    },
    searchable: {
      default: false,
    },
    multiple: {
      default: false,
    },
    needDisplayPlaceholder: {
      default: true,
    },
    closeOnSelect: {
      default: true,
    },
    identifierKey: {
      type: String,
      default: 'label',
    },
  },

  data() {
    return {
      checked: '',
    }
  },

  computed: {
    filterValues() {
      return this.filter.props.values.map(el => el.props)
    },
  },

  watch: {
    value(nval) {
      this.checked = this.normalizeFrom(nval)
    },
  },

  mounted() {
    this.checked = this.normalizeFrom(this.value)
  },

  methods: {
    submit() {
      const val = this.normalizeTo(this.checked)
      this.$emit('input', val)
    },

    normalizeTo(value) {
      const val = this.getValue(value)

      const checked = this.filterValues
        .filter(el => val.find(v => v[this.identifierKey] === el[this.identifierKey]))
        .map(el => el[this.identifierKey])
      return checked
    },

    normalizeFrom(value) {
      const val = this.getValue(value)

      const checked = this.filterValues
        .filter(el => val.find(v => v === el[this.identifierKey]))
      return checked
    },

    getValue(value) {
      let val = value

      if (!value) return []

      if (!Array.isArray(value)) {
        val = [value]
      }

      return val
    },
  },
}
</script>
