<template lang="pug">
  include ./../../../mixins
  div
    //- slot(name='top')
    +b.SECTION.search-section-wrapper.section-base
      +e.H1.header-title(v-if='pageTitle') {{ pageTitle }}
      +e.IMG.image(
        :src='imageUrl'
        :alt='altImage'
        :title='titleOfImage'
        loading="lazy"
      )
      +e.H2.title {{ titleOfPage }}
      +e.P.description {{ descriptionOfPage }}
      +b.g-row--space_20_adaptive.--appearance_spaced.search-controls-wrapper
        +b.g-cell.g-cols--12-xs.--8-xl
          search-filter(
            v-model="formdata.search"
            :filter="{ label: _('Введіть пошуковий запит') }"
          )
        +b.g-cell.g-cols--12-xs.--4-xl
          +b.BUTTON.btn-base--blue(
            @click.prevent='getData'
          ) {{ _("Поиск") }}
    div(ref="content")
      slot(name='categories')
      slot(
        v-if="!result"
        name='content'
      )
      renderer(
        v-if="result"
        :result="result"
        class-render=""
      )
</template>

<script>
import { faqListResource } from '@services/faqs.service'
import Renderer from '@components/List/Renderer'
import SearchFilter from '@components/Filters/Widgets/Search'
import { friendlyUrlParser } from '@components/Filters/transformers'

export default {
  components: {
    Renderer,
    SearchFilter,
  },

  props: {
    category: {
      type: String,
    },
    baseUrl: {
      type: String,
    },
    pageTitle: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    altImage: {
      type: String,
    },
    titleOfPage: {
      type: String,
    },
    descriptionOfPage: {
      type: String,
    },
  },

  data() {
    return {
      isLoad: false,
      result: '',
      formdata: {
        search: '',
      },
      titleOfImage: this._('Шукайте інформацію'),
    }
  },

  mounted() {
    this.parseUrl()

    window.addEventListener('popstate', () => {
      window.location.reload()
    })
  },

  methods: {
    async  getData() {
      await this.$nextTick()

      this.isLoad = true

      const params = {
        search: this.formdata.search,
        category: this.category,
      }

      faqListResource.execute(params).then(async res => {
        const { data } = res

        this.result = data

        this.updateUrl()

        await this.$nextTick()

        this.scrollToContent()

        this.isLoad = false
      })
    },

    parseUrl() {
      const filters = friendlyUrlParser()

      if (filters.search) {
        this.formdata.search = filters.search
      }
    },

    updateUrl() {
      let newUrl = this.baseUrl

      if (this.formdata.search) {
        newUrl = `${this.baseUrl}filters/search=${this.formdata.search}`
      }
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, '', newUrl)
    },

    scrollToContent() {
      const element = this.$refs.content

      element.scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>
