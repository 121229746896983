import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(VueSocialSharing)
  Vue.use(Vuex)
  Vue.use(VueClipboard)
  Vue.use(Notifications)
}

export default { install }
