import {
  createResource,
} from '@resource/resource'
import { prefixLanguage } from '@utils/urls'

const FAQS_LIST_URL_PREFIX = prefixLanguage('/knowledge-base/{category}/ajax/filters/search={search}/')

export const faqListResource = createResource(
  FAQS_LIST_URL_PREFIX
)
