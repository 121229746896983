<template>
  <select-widget
    v-bind="$attrs"
    v-on="$listeners"
    :multiple="true"
    :close-on-select="false"
  />
</template>

<script>

import SelectWidget from './Select'

export default {
  name: 'multiselect-widget',

  components: { SelectWidget },
}

</script>
