<template lang="pug">
  include ./../../../mixins
  +b.form-content-wrapper
    +b.form-body--registration
      +b.i-preloader.--absolute(v-if="isLoading")
        +e.item
      +b.form-top-info--reset-password {{ _("Відновлення пароля") }}
      validation-observer(
        ref="validator"
        tag="form"
        class="form-element"
        @submit.prevent='prepareData'
        v-slot="{ valid, errors: globalErrors }"
      )
        +b.server-error(v-if="serverError") {{ serverError[0] }}
        +b.form-inputs-wrap
          +b.form-inputs
            +b.form-inp-group
              +b.g-row--space_26.--appearance_spaced
                +b.g-cell.g-cols--12-xs.--7-md.form-inp
                  +b.input_label {{ _("Електронна пошта*") }}
                  input-group(
                    rules='required|email'
                    type="email"
                    id="email"
                    name="email"
                    placeholder="mail@gmail.com"
                    v-model="formData['email']"
                    :class="{'is-error': showInputError(globalErrors, 'email')}"
                  ).input_style
                    template(v-slot:error="{errors}")
                      transition(name="fade")
                        span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--5-md.form-inp
                  +b.BUTTON.btn-base--blue.--approve-code(
                    type="submit",
                    @click.prevent='prepareData'
                  ) {{ _("Далі") }}
    +b.form-image-wrapper
      +e.IMG.image(
          :src="'/static/img/registration_form_image.jpg'"
        )
</template>

<script>
import InfoModal from '@components/Modals/InfoModal'
import {
  restorePasswordStepOneResouce,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  data() {
    return {
      formData: {
        email: '',
      },
      serverError: '',
      redirectTo: '',
      restorePasswordStepOnePageLink: window.restorePasswordStepOnePageLink,
      isLoading: false,
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        email: this.formData.email,
        password: this.formData.password,
      })
    },
    send(sendData) {
      this.isLoading = true

      return restorePasswordStepOneResouce.execute(
        {},
        sendData
      ).then(() => {
        this.$refs.validator.reset()
        // редірект на стр профіля
        const title = this._('Дякуємо!')
        const information = 'Вам надіслано листа із посиланням для відновлення пароля!'
        this.redirectTo = window.cabinetPageLink
        this.$modal.show(
          InfoModal,
          {
            title,
            information,
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          },
          {
            'before-close': () => {
              window.location = window.loginPageLink
            },
          }
        )
      }).finally(() => {
        this.isLoading = false
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
