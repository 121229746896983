<template lang="pug">
  include ./../../../mixins
  +b.NAV.sidebar-wrapper.js-sidebar
    +b.sidebar-top-section
      +b.A.sidebar-image-wrapper(:href="indexPageLink")
        +e.IMG.image(
          :src="'/static/img/lighting_academy_logo.svg'"
        )
      +b.togle-icon#togle-icon(
        @click='toggleSidebar'
        :class="{'open': btnClose}"
      )
        span
        span
        span
        span
        span
        span
    +b.sidebar-menu-section
      template(v-for="(link, index) in preparedLinks")
        template(v-if="link.children")
          +b.A.sidebar-menu-link(
            v-if="sidebarIsCollapsed"
            :href="link.link"
          )
            +e.single
              +e.icon
                component(:is="link.icon")
              +e.text {{ link.title }}
          +b.sidebar-menu-link(
            v-else
          )
            +e.single(
              :class="{ 'is-active': toggledItems[index] }"
              @click.prevent="toggleItem(index)"
            )
              +e.icon
                component(:is="link.icon")
              +e.SPAN.text {{ link.title }}
              +e.dropdown
                icon-chevron-down
            +e.submenu(v-if="toggledItems[index]")
              +e.A.child-link(
                v-for="child in link.children"
                :href="child.link"
              ) {{ child.title }}
        +b.A.sidebar-menu-link(
          v-else
          :href="link.link"
        )
          +e.single
            +e.icon
              component(:is="link.icon")
            +e.text {{ link.title }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      btnClose: false,
      indexPageLink: window.indexPageLink,
      links: [
        {
          title: this._('Профільні дані'),
          link: window.cabinetPageLink,
          icon: 'icon-user',
          authRequired: true,
        },
        {
          title: this._('Курси'),
          link: window.coursesPageLink,
          icon: 'icon-Briefcase',
          authRequired: true,
          children: [
            { title: this._('Усі'), link: window.coursesPageLink },
            { title: this._('Не розпочато'), link: window.coursesWaitPageLink },
            { title: this._('У процесі'), link: window.coursesProgressPageLink },
            { title: this._('Завершено'), link: window.coursesFinishedPageLink },
          ],
        },
        {
          title: this._('Тести'),
          link: window.testsPageLink,
          icon: 'icon-tests',
          authRequired: true,
          children: [
            { title: this._('Доступні тести'), link: window.testsPageLink },
            { title: this._('Результати тестування'), link: window.testsResultsPageLink },
          ],
        },
        {
          title: this._('База знань'),
          link: window.faqsPageLink,
          icon: 'icon-teacher',
          authRequired: false,
        },
        {
          title: this._('Новини'),
          link: window.indexPageLink,
          icon: 'icon-news',
          authRequired: false,
        },
        {
          title: this._('Технічна підтримка'),
          link: window.feedbackPageLink,
          icon: 'icon-support',
          authRequired: false,
        },
      ],
      preparedLinks: [],
      toggledItems: {},
    }
  },

  created() {
    this.prepareNavigationItems()
  },

  mounted() {
    this.btnClose = !this.sidebarIsCollapsed

    if (1200 > window.innerWidth) {
      this.updateSidebarIsCollapsed(false)
    }
  },

  computed: {
    ...mapState('site', ['sidebarIsCollapsed']),
  },

  methods: {
    ...mapActions('site', [
      'updateSidebarIsCollapsed',
    ]),

    prepareNavigationItems() {
      const userIsNotAuthenticated = window.isAuthenticated

      this.preparedLinks = this.links.filter(el => {
        if (el.authRequired && !userIsNotAuthenticated) return false

        return true
      })
    },

    toggleSidebar() {
      const btn = window.$('#togler-sidebar-mobile-btn')
      if (this.isMobile && btn) {
        btn.click()
      }
      if (!this.isMobile) {
        this.updateSidebarIsCollapsed(!this.sidebarIsCollapsed)
      }
      window.$('.slick-slider').slick('refresh')
    },

    toggleItem(index) {
      if (this.toggledItems[index]) {
        this.$set(this.toggledItems, index, false)
      } else {
        this.$set(this.toggledItems, index, true)
      }
    },
  },
}
</script>
