<template lang="pug">
  include /mixins
  +b.popup-wrapper--forms
    +b.i-preloader.--absolute(v-if="isLoading")
      +e.item
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title--forms {{ _('Змінити адресу ел. пошти') }}
    +b.P.popup-description--forms {{ _('Введіть поточний пароль та нову адресу ел. пошти') }}
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element"
      @submit.prevent='prepareData'
      v-slot="{ valid, errors: globalErrors }"
    )
      +b.server-error(v-if="serverError") {{ serverError }}
      +b.g-row.--appearance_spaced.--space_xl
        +b.g-cell.g-cols.--12-xs
          +b.relative
            +b.input_label {{ _("Поточний пароль*") }}
            +b.I.icon.icon-password-secret.input_icon(
              @click="switchVisibility"
              v-if="typeOfPassword === 'password'"
            )
            +b.I.icon.icon-password-public.input_icon(
              @click="switchVisibility"
              v-else
            )
            input-group(
              rules='required|min:6',
              :type='typeOfPassword',
              id="password",
              name="password",
              placeholder="•••••••••••••••",
              v-model="formData['password']",
              vid="password",
              :class="{'is-error': showInputError(globalErrors, 'password')}",
            ).input_style
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
        +b.g-cell.g-cols.--12-xs
          +b.relative
            +b.input_label {{ _("Нова ел. пошта*") }}
            input-group(
              rules='required|email'
              type="email"
              id="email"
              name="email"
              placeholder="mail@gmail.com"
              v-model="formData['email']"
              :class="{'is-error': showInputError(globalErrors, 'email')}"
            ).input_style
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
        +b.g-cell.g-cols.--12-xs
          +b.BUTTON.btn-base--blue.--txt-center(
            type="submit",
            @click.prevent='prepareData'
          ) {{ _('Змінити ел. пошту') }}
</template>

<script>
import {
  createChangeEmailRequestResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  props: {
    info: {},
    promise: {},
  },
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      serverError: '',
      typeOfPassword: 'password',
      isLoading: false,
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    // submit(trigger) {
    //   this.promise(trigger)
    //   this.$emit('close')
    // },
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        email: this.formData.email,
        password: this.formData.password,
      })
    },
    send(sendData) {
      this.isLoading = true

      return createChangeEmailRequestResource.execute(
        {},
        sendData
      ).then(() => {
        this.$refs.validator.reset()
        this.promise(this.formData.email)
        this.resetData()
        this.$emit('close')
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        email: '',
        password: '',
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        const [serverError] = parsed.nonFieldErrors
        this.serverError = serverError
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    switchVisibility() {
      this.typeOfPassword = 'password' === this.typeOfPassword ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
