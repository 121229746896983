<template>
  <filter-controller
    :filters="filters"
    :receive="allProps.receive"
    :all="allProps.result"
    :loading="allProps.loading"
    :allAdditional="additionalProps.result"
    :additionalResource="additionalResource"
    :pagination='pagination'
    :totalItems="totalItems"
    :value="value"
    :label="label"
    :needSearch="needSearch"
    :needPriceFilter="needPriceFilter"
    :priceFilterTitle="priceFilterTitle"
    @input="handleFilter"
  />
</template>

<script>
import FilterController from './Controller'

export default {
  props: [
    'value',
    'allProps',
    'additionalProps',
    'pagination',
    'label',
    'additionalResource',
    'totalItems',
    'filters',
    'needSearch',
    'needPriceFilter',
    'priceFilterTitle',
  ],

  components: {
    FilterController,
  },

  data() {
    return {
      allFilters: this.value.filters,
    }
  },

  methods: {
    handleFilter(parameters) {
      this.$emit('input', parameters)
    },
  },
}
</script>
