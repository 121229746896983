<template lang="pug">
include /mixins.pug
+b.g-row.--appearance_spaced.--space_sm.--space_md-xl(
  :class="{ 'g-row--justify_center-xl': !isModal }"
)
  +b.g-cell.g-cols(
    v-for="item in items"
    :key="item.network"
    :class="{ 'g-cols--6-xs g-cols--4-md': isModal }"
  )
    +b.share-item(:style="'--network-color:'+item.color")
      +e.SHARE-NETWORK.link(
        :network="item.network"
        :url="url"
        :title="title"
      )
      +e.icon
        component(:is="item.icon")
      +e.SPAN.text {{ item.title }}
  +b.g-cell.g-cols(
    :class="{ 'g-cols--6-xs g-cols--4-md': isModal }"
  )
    ui-copy-button(
      v-slot="{ doCopy }"
      :text="url"
    )
      +b.share-item(
        @click.prevent="doCopy"
      )
        +e.icon
          icon-copy
        +e.SPAN.text {{ _("Копіювати") }}
</template>

<script>
export default {
  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    isModal: {
      type: Boolean,
    },
  },

  data() {
    return {
      items: [
        {
          network: 'facebook',
          title: this._('Facebook'),
          icon: 'icon-facebook',
          color: '#3b5998',
        },
        {
          network: 'telegram',
          title: this._('Telegram'),
          icon: 'icon-telegram',
          color: '#0088cc',
        },
        {
          network: 'viber',
          title: this._('Viber'),
          icon: 'icon-viber',
          color: '#7360f2',
        },
        {
          network: 'twitter',
          title: this._('Twitter'),
          icon: 'icon-twitter',
          color: '#1da1f2',
        },
        {
          network: 'linkedin',
          title: this._('Linkedin'),
          icon: 'icon-linkedin',
          color: '#0a66c2',
        },
        {
          network: 'whatsapp',
          title: this._('Whatsapp'),
          icon: 'icon-whatsapp',
          color: '#25d366',
        },
      ],
    }
  },
}
</script>
