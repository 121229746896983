const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = errors[x].map(messageGetter)
    return acc
  }, {})
}

export function getCommonParsedErrors(e) {
  return e.json().then(body => {
    const errors = {}
    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
    return errors
  })
}

export default {
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (
          !e.status ||
          clientError > e.status ||
          404 === e.status ||
          serverError <= e.status
        ) {
          this.serverError = 'Server Error'
          this.isLoading = false
          throw e
        }
        if (!this.updateValidator) {
          return e
        }
        return e.json().then(body => {
          this.serverError = ''
          const errors = {}
          body.errors.forEach(error => {
            if (error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })

          this.updateValidator(errors)

          return e
        })
      })
    },

    submit(isValid, data) {
      if (!isValid) {
        return Promise.reject()
      }

      this.isRequest = true
      const formdata = JSON.parse(JSON.stringify(data))
      return this.catchFormErrors(this.send(formdata))
        .finally(() => {
          this.isRequest = false
        })
    },
  },
}
