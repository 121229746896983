<template lang="pug">
  include ./../../../mixins
  +b.form-content-wrapper
    +b.form-body--registration
      +b.i-preloader.--absolute(v-if="isLoading")
        +e.item
      +b.form-top-info {{ _("Підтвердити ел. пошту") }}
      +b.form-top-info-description
        +b.SPAN.form-title {{ _("На вашу ел. пошту") }}
        +e.SPAN.email.form-title &nbsp{{ email }}&nbsp
        +b.SPAN.form-title {{ _("було відправлено листа з кодом підтвердження") }}
      validation-observer(
        ref="validator"
        tag="form"
        class="form-element"
        @submit.prevent='prepareData'
        v-slot="{ valid, errors: globalErrors }"
      )
        +b.server-error(v-if="serverError") {{ serverError }}
        +b.form-inputs-wrap
          +b.form-inputs
            +b.form-inp-group
              +b.g-row--space_26.--appearance_spaced
                +b.g-cell.g-cols.--12-xs.--6-md
                  +b.relative
                    +b.input_label {{ _("6-ти значний код*") }}
                    +b.I.icon.icon-password-secret.input_icon(
                      @click="switchVisibility"
                      v-if="typeOfPassword === 'password'"
                    )
                    +b.I.icon.icon-password-public.input_icon(
                      @click="switchVisibility"
                      v-else
                    )
                    input-group(
                      rules='required|length:6',
                      :type='typeOfPassword',
                      id="code",
                      name="code",
                      placeholder="•••••••••••••••",
                      v-model="formData['code']",
                      vid="code",
                      :class="{'is-error': showInputError(globalErrors, 'code')}",
                    ).input_style
                      template(v-slot:error="{errors}")
                        transition(name="fade")
                          span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols.--12-xs.--6-md
                  +b.BUTTON.btn-base--blue.--approve-code(
                    type="submit",
                    @click.prevent='prepareData'
                  ) {{ _("Підтвердити") }}
        +b.form-button--resend-code
          +b.BUTTON.btn-base.--empty.--resend(
            @click.prevent='sendApprovedCode()'
          )
            span {{ _("Надіслати повторно через ") }}
            span.bold {{ _("02:00") }}
            span {{ _(" хвилини") }}
    +b.form-image-wrapper--send-code
      +e.IMG.image(
          :src="'/static/img/registration_form_image.jpg'"
        )
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import { getCookie } from '@utils/cookies'
import InfoModal from '@components/Modals/InfoModal'
import {
  registrationStepTwoResource,
  sendApprovedCodeResource,
  userRegistrationInfoResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  data() {
    return {
      formData: {
        code: '',
      },
      email: '',
      userId: '',
      serverError: '',
      typeOfPassword: 'password',
      redirectTo: '',
      linkToLogin: window.loginPageLink,
      isLoading: false,
      user: {},
    }
  },
  async mounted() {
    this.email = getCookie('email')
    this.userId = getCookie('userId')
    // if (!this.email || !this.userId) {
    //   // якщо нема пошти, то повертаємо на перший крок реєстрації
    //   window.location = window.registrationPageLink
    // }
    await this.getUserInfo()
    // якщо юзер вже підтвердив пошту, то одразу до наступного кроку
    // if (this.user.finishFirstStepReg) {
    //   window.location = window.registrationPageThreeLink
    // }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        uniqueCode: this.formData.code,
      })
    },
    send(data) {
      this.isLoading = true

      return registrationStepTwoResource.execute({}, data).then(() => {
        this.$refs.validator.reset()
        // якщо все добре, то відкриваємо попап з інфою
        const title = this._('Дякуємо!')
        const information = 'Пошту підтверджено! Залишилось заповнити додаткову інформацію.'
        this.redirectTo = window.registrationPageThreeLink
        this.$modal.show(
          InfoModal,
          {
            title,
            information,
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          },
          {
            'before-close': () => {
              window.location = this.redirectTo
            },
          }
        )
        // Обнуляємо дані форми
        this.resetData()
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        code: '',
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        const [serverError] = parsed.nonFieldErrors
        this.serverError = serverError
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    async getUserInfo() {
      await userRegistrationInfoResource.execute(
        { pk: this.userId }
      ).then(({ data }) => {
        const { item } = data
        this.user = {
          isActive: item.isActive,
          finishFirstStepReg: item.finishFirstStepReg,
        }
      })
    },
    async sendApprovedCode() {
      await sendApprovedCodeResource.execute(
        {},
        { email: this.email }
      ).then(() => {
        this.$modal.show(
          InfoModal,
          {
            title: this._('Готово!'),
            information: this._('Вам надіслано новий код підтверження ел. пошти.'),
          },
          {
            height: 'auto',
            width: 360,
            adaptive: true,
            scrollable: true,
            transition: 'false',
          }
        )
      })
    },
    goToUserPersonalPage() {
      window.location.pathname = window.cabinetPageLink
    },
    switchVisibility() {
      this.typeOfPassword = 'password' === this.typeOfPassword ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
