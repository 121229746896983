<template lang="pug">
  include ./../../../mixins
  +b.form-content-wrapper
    +b.form-body--registration
      +b.i-preloader.--absolute(v-if="isLoading")
        +e.item
      +b.form-top-info {{ _("Увійдіть у свій акаунт") }}
      validation-observer(
        ref="validator"
        tag="form"
        class="form-element"
        @submit.prevent='prepareData'
        v-slot="{ valid, errors: globalErrors }"
      )
        +b.server-error(v-if="serverError") {{ serverError[0] }}
        +b.form-inputs-wrap
          +b.form-inputs
            +b.form-inp-group
              +b.g-row--space_26
                +b.g-cell.g-cols--12-xs.--6-md.form-inp
                  +b.input_label {{ _("Електронна пошта*") }}
                  input-group(
                    rules='required|email'
                    type="email"
                    id="email"
                    name="email"
                    placeholder="mail@gmail.com"
                    v-model="formData['email']"
                    :class="{'is-error': showInputError(globalErrors, 'email')}"
                  ).input_style
                    template(v-slot:error="{errors}")
                      transition(name="fade")
                        span.help-type-error(v-if='errors.length') {{ errors[0] }}
                +b.g-cell.g-cols--12-xs.--6-md.form-inp
                  +b.relative
                    +b.input_label {{ _("Пароль*") }}
                    +b.I.icon.icon-password-secret.input_icon(
                      @click="switchVisibility"
                      v-if="typeOfPassword === 'password'"
                    )
                    +b.I.icon.icon-password-public.input_icon(
                      @click="switchVisibility"
                      v-else
                    )
                    input-group(
                      rules='required',
                      :type='typeOfPassword',
                      id="password",
                      name="password",
                      placeholder="•••••••••••••••",
                      v-model="formData['password']",
                      vid="password",
                      :class="{'is-error': showInputError(globalErrors, 'password')}",
                    ).input_style
                      template(v-slot:error="{errors}")
                        transition(name="fade")
                          span.help-type-error(v-if='errors.length') {{ errors[0] }}
              +b.form-btns-wrapper
                +b.g-row--space_26.auth-btn-link-row
                  +b.g-cell.g-cols.--12-xs.--6-sm.forgot-pass-link
                    +b.A.small-reg.blue-text.hover-dark-blue.lh-180(
                      :href="restorePasswordStepOnePageLink"
                    ) {{ _("Забули пароль?") }}
                  +b.g-cell.g-cols.--12-xs.--6-sm
                    +b.BUTTON.btn-base--blue(
                      type="submit",
                      @click.prevent='prepareData'
                    ) {{ _("Увійти") }}
      +b.form-links
        +b.SPAN.dark-blue-text.small-reg.lh-180 {{ _("Не маєте акаунта?") }}
        +b.A.small-reg.blue-text.hover-dark-blue.lh-180(:href="registrationPageLink") {{ _("Зареєструватися") }}
    +b.form-image-wrapper
      +e.IMG.image(
          :src="'/static/img/registration_form_image.jpg'"
        )
</template>

<script>
import {
  loginUserResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      serverError: '',
      typeOfPassword: 'password',
      redirectTo: '',
      registrationPageLink: window.registrationPageLink,
      restorePasswordStepOnePageLink: window.restorePasswordStepOnePageLink,
      isLoading: false,
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        email: this.formData.email,
        password: this.formData.password,
      })
    },
    send(sendData) {
      this.isLoading = true

      return loginUserResource.execute(
        {},
        sendData
      ).then(() => {
        this.$refs.validator.reset()
        // редірект на стр профіля
        window.location = window.cabinetPageLink
      }).finally(() => {
        this.isLoading = false
      })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    goToUserPersonalPage() {
      window.location.pathname = window.cabinetPageLink
    },
    switchVisibility() {
      this.typeOfPassword = 'password' === this.typeOfPassword ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
