<template lang="pug">
  include ./../../../mixins
  +b.site-wrapper(
    :class="{'sidebar-is-collapsed': sidebarIsCollapsed}"
  )
    slot
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('site', ['sidebarIsCollapsed']),
  },
}
</script>
