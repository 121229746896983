import RegistrationFormStepOne from './RegistrationFormStepOne'
import RegistrationFormStepTwo from './RegistrationFormStepTwo'
import RegistrationFormStepThree from './RegistrationFormStepThree'
import AuthForm from './AuthForm'
import RestorePasswordStepOne from './RestorePasswordStepOne'
import RestorePasswordStepTwo from './RestorePasswordStepTwo'
import LogoutBtn from './LogoutBtn'
import ChangeAvatarControl from './ChangeAvatarControl'
import ChangeEmailBtn from './ChangeEmailBtn'
import ChangePasswordBtn from './ChangePasswordBtn'

export default function Components(Vue) {
  Vue.component('registration-form-step-one', RegistrationFormStepOne)
  Vue.component('registration-form-step-two', RegistrationFormStepTwo)
  Vue.component('registration-form-step-three', RegistrationFormStepThree)
  Vue.component('auth-form', AuthForm)
  Vue.component('restore-password-step-one', RestorePasswordStepOne)
  Vue.component('restore-password-step-two', RestorePasswordStepTwo)
  Vue.component('logout-btn', LogoutBtn)
  Vue.component('change-avatar-control', ChangeAvatarControl)
  Vue.component('change-email-btn', ChangeEmailBtn)
  Vue.component('change-password-btn', ChangePasswordBtn)
}
