<template lang="pug">
  include ./../../../mixins
  +b.material-slide-wrapper(
    :class="{'read': isReaded}"
  )
    +b.A.read-btn-link(
      :href='link',
      target='_blank',
      @click='readMaterial()'
    )
      +b.I.icon.icon-material.read-material-btn(
        :class="{'read-material-btn--read': isReaded}"
      )
    +e.A.title(
      :href='link',
      target='_blank'
      @click='readMaterial()'
    ) {{ title }}
</template>

<script>
import ReadMaterialModal from '@components/Modals/ReadMaterialModal'
import {
  readMaterialResource,
} from '@services/courses.service'

export default {
  props: {
    pk: {
      type: String,
    },
    isRead: {
      type: String,
    },
    link: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      isReaded: '',
    }
  },
  computed: {
    parseIsRead() {
      if ('True' === this.isRead) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.isReaded = this.parseIsRead
  },
  methods: {
    readMaterial() {
      // console.log('readMaterial')
      return readMaterialResource.execute(
        { pk: this.pk },
        { isRead: true }
      ).then(() => {
        this.isReaded = true
        window.location.reload()
      })
    },
    async openModalPromise() {
      return new Promise(resolve => {
        /**
         * @argument {boolean} arg
         */
        const promise = arg => new Promise(res => {
          res(arg)
        }).then(result => {
          resolve(result)
          this.isReaded = result
          window.location.reload()
        })
        this.$modal.show(
          ReadMaterialModal,
          {
            promise,
            pk: this.pk,
            isRead: this.isReaded,
          },
          {
            height: 'auto',
            width: 500,
            adaptive: true,
            scrollable: false,
            transition: 'false',
          }
        )
      })
    },
  },
}
</script>
