<template lang="pug">
  include /mixins
  +b.popup-wrapper--forms
    +b.i-preloader.--absolute(v-if="isLoading")
      +e.item
    +b.btn-close-popup
      +b.I.icon.icon-cross-close(
        @click='$emit("close")'
      )
    +b.popup-title--forms {{ _('Змінити пароль') }}
    +b.form-top-info-description--forms
      +b.SPAN.form-title {{ _("Введіть поточний пароль та новий пароль") }}
    validation-observer(
      ref="validator"
      tag="form"
      class="form-element"
      @submit.prevent='prepareData'
      v-slot="{ valid, errors: globalErrors }"
    )
      +b.server-error(v-if="serverError") {{ serverError }}
      +b.g-row.--appearance_spaced.--space_xl
        +b.g-cell.g-cols.--12-xs
          +b.relative
            +b.input_label {{ _("Поточний пароль*") }}
            +b.I.icon.icon-password-secret.input_icon(
              @click="switchVisibility(0)"
                v-if="typeOfPassword[0] === 'password'"
              )
            +b.I.icon.icon-password-public.input_icon(
              @click="switchVisibility(0)"
              v-else
            )
            input-group(
              rules='required|min:6',
              :type='typeOfPassword[0]',
              id="passwordOld",
              name="passwordOld",
              placeholder="•••••••••••••••",
              v-model="formData['passwordOld']",
              vid="passwordOld",
              :class="{'is-error': showInputError(globalErrors, 'passwordOld')}",
            ).input_style
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
        +b.g-cell.g-cols.--12-xs
          +b.g-row.--justify_end
            +b.g-cell
              +b.A.small-reg.blue-text.hover-dark-blue(
                :href="restorePasswordStepOnePageLink"
              ) {{ _("Забули пароль?") }}
        +b.g-cell.g-cols.--12-xs
          +b.relative
            +b.input_label {{ _("Новий пароль*") }}
            +b.I.icon.icon-password-secret.input_icon(
              @click="switchVisibility(1)"
              v-if="typeOfPassword[1] === 'password'"
            )
            +b.I.icon.icon-password-public.input_icon(
              @click="switchVisibility(1)"
              v-else
            )
            input-group(
              rules='required|min:6',
              :type='typeOfPassword[1]',
              id="passwordOne",
              name="passwordOne",
              placeholder="•••••••••••••••",
              v-model="formData['passwordOne']",
              vid="passwordOne",
              :class="{'is-error': showInputError(globalErrors, 'passwordOne')}",
            ).input_style
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
        +b.g-cell.g-cols.--12-xs
          +b.relative
            +b.input_label {{ _("Повторіть пароль*") }}
            +b.I.icon.icon-password-secret.input_icon(
              @click="switchVisibility(2)"
              v-if="typeOfPassword[2] === 'password'"
            )
            +b.I.icon.icon-password-public.input_icon(
              @click="switchVisibility(2)"
              v-else
            )
            input-group(
              rules='required|confirmed:passwordOne',
              :type='typeOfPassword[2]',
              id="passwordTwo",
              name="passwordTwo",
              placeholder="•••••••••••••••",
              v-model="formData['passwordTwo']",
              :class="{'is-error': showInputError(globalErrors, 'passwordTwo')}",
            ).input_style
              template(v-slot:error="{errors}")
                transition(name="fade")
                  span.help-type-error(v-if='errors.length') {{ errors[0] }}
        +b.g-cell.g-cols.--12-xs
          +b.BUTTON.btn-base--blue.--txt-center(
            type="submit",
            @click.prevent='prepareData'
          ) {{ _('Змінити пароль') }}
</template>

<script>
import {
  changePasswordResource,
} from '@services/users.service'
import FormMixin, { defaultValidatorErrorsParser } from '../../mixins/SendMixin'

export default {
  props: {
    promise: {},
  },
  data() {
    return {
      formData: {
        passwordOld: '',
        passwordOne: '',
        passwordTwo: '',
      },
      serverError: '',
      typeOfPassword: [
        'password',
        'password',
        'password',
      ],
      isLoading: false,
      restorePasswordStepOnePageLink: window.restorePasswordStepOnePageLink,
    }
  },
  mixins: [
    FormMixin,
  ],
  methods: {
    resetErrors() {
      this.$refs.validator.reset()
      this.nonFieldErrors = []
    },
    async prepareData() {
      const valid = await this.$refs.validator.validate()

      if (!valid) return

      this.submit(valid, {
        passwordOld: this.formData.passwordOld,
        passwordOne: this.formData.passwordOne,
        passwordTwo: this.formData.passwordTwo,
      })
    },
    send(sendData) {
      this.isLoading = true

      return changePasswordResource.execute(
        {},
        sendData
      ).then(() => {
        this.$refs.validator.reset()
        this.promise(true)
        this.resetData()
        this.$emit('close')
      }).finally(() => {
        this.isLoading = false
      })
    },
    resetData() {
      this.formData = {
        passwordOld: '',
        passwordOne: '',
        passwordTwo: '',
      }
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      if ('nonFieldErrors' in parsed) {
        const [serverError] = parsed.nonFieldErrors
        this.serverError = serverError
      } else {
        this.serverError = parsed.message
      }
      this.$refs.validator.setErrors(parsed)
      this.isLoading = false
    },
    switchVisibility(index) {
      this.typeOfPassword[index] = 'password' === this.typeOfPassword[index] ? 'text' : 'password'
      const pass = this.typeOfPassword
      this.typeOfPassword = ''
      this.typeOfPassword = pass
    },
    showInputError(errors, inpName) {
      if (errors[inpName] && 0 < errors[inpName].length) {
        return true
      }
      return false
    },
  },
}
</script>
