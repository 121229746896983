<template lang="pug">
  include /mixins
  ui-click-outside(:do="hide")
    +b.range-slider
      +e.heading(@click.prevent="open")
        +b.multiselect.form-single-select(:class="{'multiselect--active': isOpen}")
          +e.select
          +e.tags
            +e.single
              +e.SPAN.placeholder {{ filter.caption }}
                span(v-if="value && value.length") : {{ value.join(' - ') }}
      +e.content(:class="{'is-active': isOpen}")
        +e.wrapper
          +e.number.--ta_left
            +b.P.ds-caption.--size_sm.--color_black.--weight_bold {{ min|prettifyPrice }}
          +e.process
            range-slider(
              v-model="internal"
              transition="0.2"
              tooltip="always"
              :clickable="false"
              :drag-on-click="true"
              :dot-size="10"
              :height="4"
              :min="min"
              :max="max"
              :contained="true"
              :interval="interval"
              @drag-end="update"
            )
          +e.number.--ta_right
            +b.P.ds-caption.--size_sm.--color_black.--weight_bold {{ max|prettifyPrice }}
          //- +e.button
          //-   +b.BUTTON.btn-red.--rounded(@click.prevent="update") {{ _("ОК") }}
</template>

<script>
/* eslint-disable */
const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === b ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

const decimalCount = number => {
  const numberAsString = number.toString()
  if (numberAsString.includes('.')) {
    return numberAsString.split('.')[1].length
  }
  return 0
}

export default {
  name: 'range-widget',

  props: ['value', 'filter'],

  data() {
    return {
      internal: [0, 1],
      interval: 1,
      min: 0,
      max: 1,
      isOpen: false,
    }
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    value: { immediate: true, handler: 'updateInternal' },
  },

  methods: {
    hide() {
      this.isOpen = false
    },

    open() {
      this.isOpen = true
    },

    clearMinMax() {
      const [from, to] = this.filter.props.values

      this.min = parseFloat(Math.max(from.props.value || 0).toFixed(2))
      this.max = parseFloat(Math.min(to.props.value || 0).toFixed(2))

      const isInteger = Number.isInteger(this.min) && Number.isInteger(this.max)

      this.interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2))

      if (this.interval === 1 && !isInteger) {
        const minDecimal = decimalCount(this.min)
        const maxDecimal = decimalCount(this.max)
        const exponent = minDecimal > maxDecimal ? minDecimal : maxDecimal
        const d = 1 / Math.pow(10, exponent)
        this.interval = parseFloat((d).toFixed(exponent))
      }

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value)
    },

    update() {
      const value = this.clear(this.internal)

      this.input(value)
    },

    input(value) {
      this.$emit('input', value)
    },

    clear(value) {
      let [min, max] = value
      const [minVal, maxVal] = this.normalize([min, max])

      min = minVal
      max = maxVal

      if (min === this.min && max === this.max) {
        return []
      }

      return [min, max]
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      const [min, max] = value

      return [min || this.min, max || this.max]
    },
  },
};

</script>
