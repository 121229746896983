import {
  createResource,
  postResource,
  // patchResource,
} from '@resource/resource'
// import {
//   simpleTransformer,
// } from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const REGISTRATION_LINK_STEP_ONE = prefixAPI('/auth/registration-step-one/', 'users')
const REGISTRATION_LINK_STEP_TWO = prefixAPI('/auth/registration-step-two/', 'users')
const REGISTRATION_LINK_STEP_THREE = prefixAPI('/auth/registration-step-three/', 'users')
const SEND_APPROVED_CODE_LINK = prefixAPI('/auth/send-approved-code/', 'users')
const LOGIN_USER_LINK = prefixAPI('/auth/login/', 'users')
const LOGOUT_USER_LINK = prefixAPI('/auth/logout/', 'users')
const RESTORE_PASSWORD_STEP_ONE_LINK = prefixAPI('/auth/restore-password-request/', 'users')
const RESTORE_PASSWORD_SET_NEW_LINK = prefixAPI('/auth/restore-password-set-new/', 'users')
const CREATE_CHANGE_EMAIL_LINK = prefixAPI('/auth/change-email-request/', 'users')
const CONFIRM_CHANGE_EMAIL_LINK = prefixAPI('/auth/change-email-confirm/', 'users')
const CHANGE_PASSWORD_LINK = prefixAPI('/auth/change-password/', 'users')

const USER_REGISTRATION_INFO_LINK = prefixAPI('/registration-info/{?pk}', 'users')
const USER_DETAIL_INFO_LINK = prefixAPI('/user-detail-info/{?pk}', 'users')
const USER_SET_AVATAR_LINK = prefixAPI('/user-set-avatar/{?pk}', 'users')

const TRADING_TEAMS_LIST_LINK = prefixAPI('/trading-teams-list/{company}/{?search,page}', 'users')
const COMPANIES_LIST_LINK = prefixAPI('/companies-list/{?search,page}', 'users')

export const registrationStepOneResource = createResource(
  REGISTRATION_LINK_STEP_ONE, postResource
)
export const registrationStepTwoResource = createResource(
  REGISTRATION_LINK_STEP_TWO, postResource
)
export const registrationStepThreeResource = createResource(
  REGISTRATION_LINK_STEP_THREE, postResource
)
export const loginUserResource = createResource(
  LOGIN_USER_LINK, postResource
)
export const logoutUserResource = createResource(
  LOGOUT_USER_LINK, postResource
)
export const restorePasswordStepOneResouce = createResource(
  RESTORE_PASSWORD_STEP_ONE_LINK, postResource
)
export const restorePasswordSetNewResouce = createResource(
  RESTORE_PASSWORD_SET_NEW_LINK, postResource
)
export const sendApprovedCodeResource = createResource(
  SEND_APPROVED_CODE_LINK, postResource
)
export const createChangeEmailRequestResource = createResource(
  CREATE_CHANGE_EMAIL_LINK, postResource
)
export const confirmChangeEmailRequestResource = createResource(
  CONFIRM_CHANGE_EMAIL_LINK, postResource
)
export const changePasswordResource = createResource(
  CHANGE_PASSWORD_LINK, postResource
)

export const userRegistrationInfoResource = createResource(
  USER_REGISTRATION_INFO_LINK
)
export const userDetailInfoResource = createResource(
  USER_DETAIL_INFO_LINK
)
export const userSetAvatarResource = createResource(
  USER_SET_AVATAR_LINK, postResource
)
export const tradingTeamsListResource = createResource(
  TRADING_TEAMS_LIST_LINK
)
export const companiesListResource = createResource(
  COMPANIES_LIST_LINK
)
